export default async function responseParse(response: Response) {
  const result = await response.json();
  switch (response.status) {
    case 200:
      return result;
    case 201:
      return result;
    case 400:
      throw result;
    case 401:
      if (result && !result.auth) {
        sessionStorage.removeItem("ags_token");
        sessionStorage.removeItem("role");
        sessionStorage.removeItem("user");
        window.location.href = "/";
        return;
      } else {
        throw result;
      }
    case 403:
      throw result;
    case 404:
      throw result;
    case 500:
      throw result;
    default:
      throw result;
  }
}
