import { createAsyncThunk } from "../../../middlewares/asyncThunk";
import { fetchUsersCount } from "../../../services/api/dashboard.service";

export const fetchUserCountEffectAction = "dashboardWidget/dashboardWidgetCount";

export const fetchUserCountEffect = () =>
  createAsyncThunk(fetchUserCountEffectAction, async function () {
    const response = await fetchUsersCount();
    return response;
  });
