import { VirtualAgentState } from "../../state/reducers/virtual-agent/virtual-agent-reducer";

export interface SelectedAgentDetails {
  cxt_id: string;
  agent_id: string;
  project_id: string;
  environment: string;
  agent_name: string
  is_imported: boolean;
  sub_type: string;
  analytics_enabled: boolean;
  df_agent_mapping: string;
} 

export default function ExtractAgentDetails(agentInfo: VirtualAgentState): SelectedAgentDetails {
  return {
    environment: agentInfo.environment,
    agent_id: agentInfo.selectedAgent?.agent_id as string,
    cxt_id: agentInfo.selectedAgent?.cxt_id as string,
    project_id: agentInfo.selectedAgent?.id as string,
    agent_name: agentInfo.selectedAgent?.name as string,
    is_imported: agentInfo.selectedAgent?.is_imported as boolean,
    sub_type: agentInfo.selectedAgent?.sub_type as string,
    analytics_enabled: agentInfo.selectedAgent?.analytics_enabled as boolean,
    df_agent_mapping: agentInfo.selectedAgent?.dfAgentMapping as string 
  };
}
