import { use } from "i18next";
import { createAsyncThunk } from "../../../middlewares/asyncThunk";
import { parseJwt } from "../../../screens/dashboard/navigation/config";
import Login, {
  GoogleAuthPayload,
  googleLogin,
} from "../../../services/api/login-service";
import {
  ResetPassword,
  ForgotPassword,
  ChangePassword,
  VerifyOTP,
} from "../../../services/api/reset-password-service";
import { AcceptPolicy } from "../../../services/api/users-service";
const sliceName = "login";

export interface ResetPasswordState {
  email?: string;
  old_password: string;
  new_password: string;
  confirm_password?: string;
  token: string;
}
export interface ForgotPasswordState {
  email: string;
}

export interface LoginState {
  isNewUser?: boolean;
  isAuthenticated: boolean;
  loading: boolean;
  gloading?: boolean;
  isUserOnboarded: boolean;
  isPolicyAccepted: boolean;
  otpStatus?: string;
  lastAccessedEnvironment: string;
  email?: string;
  name?: string;
  credentials: {
    username: string;
    password: string;
  };
  firstName: string;
  lastName: string;
  role: string;
  organizationName: string;
  orgID: string;
  permissions: [];
}

const token = sessionStorage.getItem("ags_token") || "";
let user = sessionStorage.getItem("user") || "";

const parsedToken = parseJwt(token);
const currentTime = new Date().getTime() / 1000;
const isAuthenticated = parsedToken ? parsedToken.exp >= currentTime : false;
const email = parsedToken?.email;
const name = parsedToken?.firstName + " " + parsedToken?.lastName;

const { isPolicyAccepted, last_accessed_environment } = parsedToken || {
  isPolicyAccepted: false,
  last_accessed_environment: "sandbox",
};
let userCache: any;
let cacheRole: any;
if (user) {
  userCache = JSON.parse(user);
  cacheRole = `${userCache?.role} ${
    userCache?.organization?.organizationType || ""
  }`;
}

const initialState: LoginState = {
  isAuthenticated,
  loading: false,
  gloading: false,
  isUserOnboarded: true,
  isPolicyAccepted: isPolicyAccepted,
  lastAccessedEnvironment: last_accessed_environment,
  credentials: {
    username: "",
    password: "",
  },
  email: email,
  firstName: userCache?.firstName || "",
  lastName: userCache?.lastName || "",
  // trim is creating issue when you laod for the fierst time
  role: cacheRole !== undefined ? cacheRole.trim() : "",
  organizationName: userCache?.organization.name || "",
  orgID: userCache?.organization.id || "",
  permissions: userCache?.permissions || "",
  name: userCache?.firstName || "",
};

export const USER_LOGIN_ACTION = "login/login-user";
export const USER_GOOGLE_LOGIN_ACTION = "login/google-login-user";
export const RESET_PASSWORD_ACTION = "login/reset-password";
export const FORGOT_PASSWORD_ACTION = "login/forgot-password";
export const CHANGE_PASSWORD_ACTION = "login/change-password";
export const VERIFY_OTP_ACTION = "login/verify-otp";
export const ACCEPT_POLICY_ACTION = "login/accept-policy";

export const loginAsyncEffect = (username: string, password: string) =>
  createAsyncThunk(USER_LOGIN_ACTION, async function () {
    const response = await Login(username, password);
    return response;
  });

export const googleAuthEffect = (authPayload: GoogleAuthPayload) =>
  createAsyncThunk(USER_GOOGLE_LOGIN_ACTION, async function () {
    const response = await googleLogin(authPayload);
    return response;
  });

export const resetPasswordEffect = (resetPasswordData: any) =>
  createAsyncThunk(RESET_PASSWORD_ACTION, async function () {
    const response = await ResetPassword(resetPasswordData);
    return response;
  });

export const changePasswordEffect = (resetPasswordData: any) =>
  createAsyncThunk(CHANGE_PASSWORD_ACTION, async function () {
    const response = await ChangePassword(resetPasswordData);
    return response;
  });

export const forgotPasswordEffect = (forgotPasswordData: any) =>
  createAsyncThunk(FORGOT_PASSWORD_ACTION, async function () {
    const response = await ForgotPassword(forgotPasswordData);
    return response;
  });

export const verifyOTPEffect = (otpData: any) =>
  createAsyncThunk(VERIFY_OTP_ACTION, async function () {
    const response = await VerifyOTP(otpData);
    return response;
  });

export const updatePolicyAcceptanceEffect = (
  policyData: {
    email: string;
    isPolicyAccepted: boolean;
  },
  onCompleted: () => void
) =>
  createAsyncThunk(ACCEPT_POLICY_ACTION, async function () {
    const response = await AcceptPolicy(policyData);
    onCompleted();
    return response;
  });

function reducer(state: LoginState, action: { type: string; payload?: any }) {
  switch (action.type) {
    case `${USER_LOGIN_ACTION}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${USER_LOGIN_ACTION}-completed`: {
      sessionStorage.setItem("ags_token", action.payload.token);
      const parsedToken = parseJwt(action.payload.token);
      const email = parsedToken?.email;
      const name = parsedToken?.firstName + " " + parsedToken?.lastName;
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        isNewUser: false,
        isUserOnboarded: true,
        credentials: {
          username: "",
          password: "",
        },
        email: email,
        name: name,
        lastAccessedEnvironment: "",
      };
    }
    case `${USER_LOGIN_ACTION}-rejected`:
      return {
        ...state,
        gloading: false,
        isAuthenticated: false,
        loading: false,
      };
    case `${USER_GOOGLE_LOGIN_ACTION}-pending`:
      return {
        ...state,
        gloading: true,
      };
    case `${USER_GOOGLE_LOGIN_ACTION}-completed`: {
      const { data } = action.payload;
      const role = `${data.role} ${data?.organization?.organizationType || ""}`;

      sessionStorage.setItem("role", role.trim());
      sessionStorage.setItem("user", JSON.stringify(data));
      sessionStorage.setItem("ags_token", action.payload.token);

      return {
        ...state,
        gloading: false,
        isAuthenticated: true,
        isNewUser: false,
        isUserOnboarded: true,
        credentials: {
          username: "",
          password: "",
        },
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        role: role.trim(),
        organizationName: data.organization.name,
        permissions: data.permissions,
        orgID: data?.organization?.id || "",
        name: data.firstName,
      };
    }
    case `${USER_GOOGLE_LOGIN_ACTION}-rejected`:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    case `${RESET_PASSWORD_ACTION}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${RESET_PASSWORD_ACTION}-completed`: {
      sessionStorage.removeItem("ags_token");
      window.location.href = "/";
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        isNewUser: false,
      };
    }
    case `${RESET_PASSWORD_ACTION}-rejected`:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    case `${CHANGE_PASSWORD_ACTION}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${CHANGE_PASSWORD_ACTION}-completed`: {
      return {
        ...state,
        loading: false,
      };
    }
    case `${CHANGE_PASSWORD_ACTION}-rejected`:
      return {
        ...state,
        loading: false,
      };
    case `${FORGOT_PASSWORD_ACTION}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${FORGOT_PASSWORD_ACTION}-completed`: {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        isNewUser: false,
        otpStatus: "sent",
        email: action?.payload?.email,
      };
    }
    case `${FORGOT_PASSWORD_ACTION}-rejected`:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        otpStatus: "notsent",
      };
    case `${VERIFY_OTP_ACTION}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${VERIFY_OTP_ACTION}-completed`: {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        isNewUser: false,
        otpStatus: "verified",
        email: action?.payload?.email,
      };
    }
    case `${VERIFY_OTP_ACTION}-rejected`:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        isOTPSent: false,
      };
    case "app/logOut": {
      // if( process.env.REACT_ENVIRONMENT === "production" ) {
      //   sessionStorage.removeItem("ags_token")
      // }

      sessionStorage.removeItem("ags_token");
      sessionStorage.removeItem("role");
      sessionStorage.removeItem("user");
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    case "login/userOnboarded":
      return {
        ...state,
        isUserOnboarded: true,
      };
    case "login/updateCredentials":
      return {
        ...state,
        credentials: {
          ...state.credentials,
          [action.payload.type]: action.payload.value,
        },
      };
    case `${ACCEPT_POLICY_ACTION}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${ACCEPT_POLICY_ACTION}-completed`: {
      return {
        ...state,
        loading: false,
        isUserOnboarded: true,
        isPolicyAccepted: true,
      };
    }
    case `${ACCEPT_POLICY_ACTION}-rejected`:
      return {
        ...state,
        loading: false,
        isUserOnboarded: false,
        isPolicyAccepted: false,
      };
    default:
      return {
        ...state,
      };
  }
}

const loginSlice = {
  sliceName,
  initialState,
  reducer,
};

export default loginSlice;
