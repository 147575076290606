import { createTheme } from "@mui/material/styles";
import shadows, { Shadows } from "@mui/material/styles/shadows";

export const DarkTheme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    button: {
      textTransform: "none"
    }
  },
  backgrounds: {
    level1: '#212121',
    level2: '#212121',
    level3: "#181818"
  },
  status: {
    danger: "yellow"
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#20B283",
      contrastText: "#fff"
    },
    secondary: {
      main: "#fb8500"
    },
    text: {
      primary: "#d0cccc",
      secondary: "#66707a"
    },
    background: {
      default: "#282828",
      paper: "#181818"
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#282828"
        }
      }
    }
  },
  shadows: shadows.map((v, index) =>
    index === 1 ? "0px 2px 20px rgba(0, 0, 0, 0.15)" : v
  ) as Shadows
});
