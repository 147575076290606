import { ComponentType, lazy, LazyExoticComponent } from "react";
import { agentAssistMenus } from "./sidebar-menus/agent-assist-menus";
import { agentDesktopMenus } from "./sidebar-menus/agent-desktop-menus";
import { MenuItem } from "./dashboard-drawer/dashboard-drawer";
import { analyticsMenu } from "./sidebar-menus/analytics-menus";
import { agentMenus } from "./sidebar-menus/agent-menus";
import { optimizerMenu } from "./sidebar-menus/optimizer-menu";
import { userManagementMenus } from "./sidebar-menus/user-management-menus";
import { billingMenus } from "./sidebar-menus/billing-menus";
import { HeaderInterface } from "./header/header";
import { BoxProps } from "@mui/system";
import {
  AgentAssistURLs,
  AgentDesktopURLs,
  AppURLs,
  BillingURLs,
  CDURLs,
  InsightsURLs,
  OptimizerURLs,
  VirtualAgentURLs,
  SuperAdmin,
} from "./urls-config";
import { adminManagementMenus } from "./sidebar-menus/super-admin";

const NluOnlyIntents = lazy(
  () =>
    import(
      /* webpackPrefetch: true,webpackChunkName: "nlu_only-intents" */ "../../library/only-intents/index"
    )
);

const BotDetail = lazy(
  () =>
    import(
      /* webpackPrefetch:true,webpackChunkName: "nlu_only-intents" */ "../../library/only-intents/only-intent-warehouse/bot-details-screen"
    )
);

const SkillDetail = lazy(
  () =>
    import(
      /* webpackPrefetch:true,webpackChunkName: "nlu_only-intents" */ "../../library/only-intents/only-intent-warehouse/skill-details-screen"
    )
);

const IntentDetail = lazy(
  () =>
    import(
      /* webpackPrefetch:true,webpackChunkName: "nlu_only-intents" */ "../../library/only-intents/only-intent-warehouse/intent-details-screen"
    )
);

const AdminDashboard = lazy(
  () =>
    import(
      /* webpackPrefetch:true,webpackChunkName: "admin-dashboard" */ "../../library/super-admin/super-admin-dashboard"
    )
);

const TenantOnBaord = lazy(
  () =>
    import(
      /* webpackPrefetch:true,webpackChunkName: "tenant" */ "../../library/super-admin/manage-tenant"
    )
);

const ManageUsers = lazy(
  () =>
    import(
      /* webpackPrefetch:true,webpackChunkName: "manage_users" */ "../../library/super-admin/manage-users"
    )
);
const UsuageAnalytics = lazy(
  () =>
    import(
      /* webpackPrefetch:true,webpackChunkName: "manage_users" */ "../../library/super-admin/usuage-analytics"
    )
);
const GcpProjects = lazy(
  () =>
    import(
      /* webpackPrefetch:true,webpackChunkName: "manage_users" */ "../../library/super-admin/gcp-projects"
    )
);

export interface QRoute {
  path: string;
  exact: boolean;
  component?: LazyExoticComponent<ComponentType<any>>;
  routes?: QRoute[];
  redirect?: string;
  private?: boolean;
  menu?: MenuItem[];
  header?: boolean;
  headerProps?: HeaderInterface;
  contentProps?: BoxProps;
  backUrl?: string;
  backName?: string;
}

export const routes: QRoute[] = [
  {
    path: VirtualAgentURLs.NLUOnlyIntents,
    component: NluOnlyIntents,
    private: true,
    header: false,
    exact: true,
  },
  {
    path: VirtualAgentURLs.VAurl,
    component: BotDetail,
    private: true,
    header: false,
    exact: true,
  },
  {
    path: VirtualAgentURLs.SkillUrl,
    component: SkillDetail,
    private: true,
    header: false,
    exact: true,
  },
  {
    path: VirtualAgentURLs.IntentUrl,
    component: IntentDetail,
    private: false,
    header: false,
    exact: true,
  },
  {
    path: SuperAdmin.AdminUrl,
    exact: false,
    private: true,
    header: true,
    menu: adminManagementMenus,
    // backName: "Back to Dashboard",
    // backUrl: AppURLs.OverviewUrl,
    headerProps: {
      showWarehouse: false,
    },
    routes: [
      {
        path: SuperAdmin.AdminUrl,
        component: AdminDashboard,
        exact: true,
      },
      {
        path: SuperAdmin.DashboardURL,
        component: AdminDashboard,
        exact: true,
      },
      {
        path: SuperAdmin.TenantUrl,
        component: TenantOnBaord,
        exact: true,
      },
      {
        path: SuperAdmin.ManageCustomersUrl,
        component: TenantOnBaord,
        exact: true,
      },
      {
        path: SuperAdmin.ManageUsersUrl,
        component: ManageUsers,
        exact: true,
      },
      {
        path: SuperAdmin.UsuageAnalyticsUrl,
        component: UsuageAnalytics,
        exact: true,
      },
      {
        path: SuperAdmin.GcpProjectsUrl,
        component: GcpProjects,
        exact: true,
      },
    ],
  },
];

export const superAdminRoutes: QRoute[] = [
  {
    path: SuperAdmin.AdminUrl,
    exact: false,
    private: true,
    header: false,
    menu: adminManagementMenus,
    // backName: "Back to Dashboard",
    // backUrl: AppURLs.OverviewUrl,
    headerProps: {
      showWarehouse: false,
    },
    routes: [
      {
        path: SuperAdmin.AdminUrl,
        component: AdminDashboard,
        exact: true,
      },
      {
        path: SuperAdmin.DashboardURL,
        component: AdminDashboard,
        exact: true,
      },
      {
        path: SuperAdmin.TenantUrl,
        component: TenantOnBaord,
        exact: true,
      },
      {
        path: SuperAdmin.ManageCustomersUrl,
        component: TenantOnBaord,
        exact: true,
      },

      {
        path: SuperAdmin.ManageUsersUrl,
        component: ManageUsers,
        exact: true,
      },
      {
        path: SuperAdmin.UsuageAnalyticsUrl,
        component: UsuageAnalytics,
        exact: true,
      },
    ],
  },
];
export const VirtualAgentRoutes: QRoute[] = [];

export const OptimizerRoutes: QRoute[] = [];

export const QInsightsRoutes: QRoute[] = [];

export const ConverstaionDesingerRoutes: QRoute[] = [];

export const WelcomeRoutes: QRoute[] = [];

export function parseJwt(token: string = "") {
  try {
    if (token.length > 0) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    }
    return null;
  } catch (e) {
    console.log(e);
    // sessionStorage.removeItem("ags_token");
    // window.open("/", "_self");
    // window.location.href = "/login/one";
  }
}
