import {
  createLoggerUrl,
  fetchLogsUrl,
  fetchLookerDashboardUrl,
} from "../../environment/environment.dev";
import { interceptor } from "../../middlewares/authenticatedFetch";
import responseParser from "../../middlewares/responseParser";
import { Log } from "../../state/reducers/logging/logging-reducer";
import { SelectedAgentDetails } from "../utils/extractAgentDetails";

export const fetchLogs = async (
  type: string,
  AgentDetails: SelectedAgentDetails,
  page: number,
  limit: number
) => {
  // const selectedAgent = "dialogflow_es_qai-insurance-health-prod";
  const { cxt_id, environment } = AgentDetails;
  const compositeId = `${cxt_id}__${environment}__global`;
  if (compositeId) {
    const fetchAnalyticsLogsUrlModified = `${fetchLogsUrl}/${type}?compositeAgentId=${compositeId}&page=${page}&limit=${limit}`;
    const response = await interceptor(fetchAnalyticsLogsUrlModified);
    const result: {
      details: { logs: { rows: Log[], totalRows: number } };
      status: number;
      message: string;
    } = await responseParser(response);
    if (result.status === 200) {
      return result.details.logs;
    } else {
      throw result;
    }
  } else {
    throw { msg: "No Agent selected" };
  }
};

export const fetchAnalyticsUrl = async (AgentDetails: SelectedAgentDetails, dashName: string) => {
  // const selectedAgent = "dialogflow_es_qai-insurance-health-prod";
  const { cxt_id, environment } = AgentDetails;
  const compositeId = `${cxt_id}__${environment}__global`;

  const fetchLookerDashboardUrlModified = `${fetchLookerDashboardUrl}?compositeAgentId=${compositeId}`;

  const response = await interceptor(fetchLookerDashboardUrlModified, {
    method: "POST",
    body: JSON.stringify({
      dashName,
      workspace: environment
    }),
    headers: { "content-type": "application/json" },
  });
  const result: { details: { url: string }; status: number } =
    await responseParser(response);
  if (result.status === 200) {
    return result.details.url as string;
  } else {
    throw result;
  }
};

export const centralLogger = async (message?: any) => {
  const response = await interceptor(createLoggerUrl, {
    method: "POST",
    body: JSON.stringify(message),
    headers: { "content-type": "application/json" },
  });

  if (response.status === 200) {
    return response;
  } else {
    throw response;
  }
};
