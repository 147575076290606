import logger from "../middlewares/logger";

export const combineReducers =
  (slices: any, options?: { log?: boolean }) => (state: any, action: any) => {
    const currenState = Object.keys(slices).reduce(
      // use for..in loop, if you prefer it
      (acc, prop) => ({
        ...acc,
        [prop]: slices[prop](acc[prop], action),
      }),
      state
    );

    if (options?.log) logger(action, state, currenState);

    return currenState;
  };
