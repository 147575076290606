import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/system/Box";
import { useStoreContext } from "../../state/agent-studio-context";
// import { MockLogin } from "../../services/mock-api/login-service";
import {
  googleAuthEffect,
  loginAsyncEffect,
  LoginState,
} from "../../state/reducers/login/login-reducer";
import CircularProgress from "@mui/material/CircularProgress";
import QLoaderButton from "../../components/q-loading-button/q-loading-button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import GoogleIcon from "../../components/icons/google-icon";
import useAgentState from "../../hooks/useAgentState";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { GoogleAuthPayload } from "../../services/api/login-service";
import Link from "@mui/material/Link";
import firebaseIntializeApp from "../../services/firebase/real-time-database/intializeapp";
import useAppState from "../../hooks/useAppState";
import { Redirect, useHistory } from "react-router-dom";
import { margin } from "@mui/system";

// eslint-disable-next-line no-useless-escape
export const EMAIL_VALIDATION_REGEX =
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/g;

const disableLoginCreds: boolean = true;

export default function LoginTwo() {
  const provider = new GoogleAuthProvider();
  // Set the prompt property to 'select_account'
  provider.setCustomParameters({
    prompt: "select_account",
  });

  const { dispatch, state } = useStoreContext();

  const { windowInnerHeight } = useAppState();
  const loginContainerHeight = windowInnerHeight * 0.56;
  const { push } = useHistory();
  const loginState = state.login as LoginState;

  const [errorState, setErrorState] = useState<{
    error?: boolean;
    errorMessage?: string;
    key?: string;
  }>({
    error: false,
  });

  function captureCredentials(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) {
    dispatch({
      type: "login/updateCredentials",
      payload: { value: e.target.value.trim(), type },
    });
  }

  async function login(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    const { username, password } = loginState.credentials;

    if (!username) {
      return setErrorState((prevState) => ({
        ...prevState,
        error: true,
        errorMessage: "Username is required",
        key: "username",
      }));
    } else if (!username.match(EMAIL_VALIDATION_REGEX)) {
      return setErrorState((prevState) => ({
        ...prevState,
        error: true,
        errorMessage: "Enter valid email id.",
        key: "username",
      }));
    }

    if (!password) {
      return setErrorState((prevState) => ({
        ...prevState,
        error: true,
        errorMessage: "Password is required",
        key: "password",
      }));
    }

    setErrorState((prevState) => ({
      ...prevState,
      error: false,
    }));
    const auth = getAuth();
    try {
      const result = await signInWithEmailAndPassword(auth, username, password);
      const token = await result.user?.getIdToken();
      if (result) {
        const tempAuthData: GoogleAuthPayload = {
          email: result.user?.email || "",
          token: token,
        };
        dispatch(googleAuthEffect(tempAuthData));
      }
    } catch (e: any) {
      dispatch({
        type: "app/showMessage",
        payload: "User login failed! Please Check your email id!",
      });
    }
  }

  async function googleSignin() {
    firebaseIntializeApp();
    const auth = getAuth();
    const result = await signInWithPopup(auth, provider);
    const token = await result.user?.getIdToken();
    if (token) {
      const tempAuthData: GoogleAuthPayload = {
        email: result.user?.email || "",
        token: token,
      };
      dispatch(googleAuthEffect(tempAuthData));
    }
  }

  const { t, ready } = useTranslation();

  const { environment } = useAgentState();
  // const isTokenFound = !!sessionStorage.getItem("ags_token");

  function navigateToForgotPassword() {
    window.location.href = "/forgot-password";
  }
  return (
    <Box
      p={1}
      m="auto"
      sx={{
        width: {
          md: 400,
          sm: 400,
          xs: 350,
        },
      }}
    >
      {loginState.isAuthenticated && loginState.isUserOnboarded && (
        <Redirect to={`/dashboard`} />
      )}
      {loginState.isAuthenticated && loginState.isNewUser && (
        <Redirect
          to={`/reset-password?token=${sessionStorage.getItem("ags_token")}`}
        />
      )}
      {loginState.isAuthenticated &&
        !loginState.isNewUser &&
        !loginState.isUserOnboarded && <Redirect to="/onboarding/welcome" />}
      <div style={{ marginTop: "50%" }}>
        <Typography
          mb={0.5}
          fontSize={22}
          fontWeight="bolder"
          color="text.primary"
        >
          {ready && t("welcome_to_cxt")}
        </Typography>
        <Typography
          sx={{ textAlign: "center" }}
          mb={3}
          fontSize={18}
          color="text.secondary"
        >
          {ready && t("sign_in_text")}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
          minHeight={loginContainerHeight}
        >
          <Box flex={1}>
            <Button
              size="large"
              variant="text"
              sx={{ boxShadow: 2, bgcolor: "background.default" }}
              color="inherit"
              disableFocusRipple
              fullWidth
              onClick={googleSignin}
            >
              <GoogleIcon fontSize="small" sx={{ mx: 1 }} />
              {ready && t("continue_with_google")}
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  );
}
