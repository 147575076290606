import { createTheme, Theme, responsiveFontSizes } from "@mui/material/styles";
import shadows, { Shadows } from "@mui/material/styles/shadows";

declare module "@mui/material/styles" {
  interface Theme {
    backgrounds: {
      level1: string;
      level2: string;
      level3?: string;
    };
    status: {
      danger: string;
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    backgrounds?: {
      level1?: string;
      level2?: string;
      level3?: string;
    };
    status: {
      danger: string;
    }
  }
}

export const LightThemeConfig = {
  typography: {
    fontFamily: '"Poppins", sans-serif',
    button: {
      textTransform: "none",
    },
  },
  backgrounds: {
    level1: '#f8f8f8',
    level2: '#f6f7fa',
    level3: "#dfe3ee"
  },
  status: {
    danger: "red"
  },
  palette: {
    primary: {
      main: "#20B283",
      contrastText: "#fff",
    },
    secondary: {
      main: "#223E80",
    },
    text: {
      secondary: "#8692A6",
    },
    background: {
      paper: "#f2f2f2",
      default: "#fff",
      // level1: '#f8f8f8'
    },
  },
  shadows: shadows.map((v, index) =>
    index === 1 ? "0px 2px 20px rgba(0, 0, 0, 0.15)" : v
  ) as Shadows,
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff"
        },
        paper: {
          backgroundColor: "#fff"
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        }
      }
    },
  },
} as Theme;

export const LightTheme = responsiveFontSizes(createTheme(LightThemeConfig));
