/* eslint-disable no-throw-literal */
import { googleAuthUrl, serverLoginUrl } from "../../environment/environment.dev";

interface CustomErrorResponse {
  code: number;
  errors: any;
  message: string;
  name: string;
}

interface LoginSuccessResponse {
    msg: string;
    token: string;
}
export interface GoogleAuthPayload {
    email: string;
    token: string;
}

const Login = async (username: string, password: string) => {
  const loginResponse = await fetch(serverLoginUrl, {
    method: "POST",
    body: JSON.stringify({ email: username, password }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const response = await loginResponse;
  const formattedResponse = await response.json();
  switch (response.status) {
    case 200:
      return formattedResponse as LoginSuccessResponse;
    case 400:
      throw formattedResponse as CustomErrorResponse;
    case 401:
      throw formattedResponse as CustomErrorResponse;
    default:
      throw formattedResponse as CustomErrorResponse;
  }
};


export const googleLogin = async (payload: GoogleAuthPayload) => {
  const loginResponse = await fetch(googleAuthUrl, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const response = await loginResponse;
  const formattedResponse = await response.json();
  switch (response.status) {
    case 200:
      return formattedResponse as LoginSuccessResponse;
    case 400:
      throw formattedResponse as CustomErrorResponse;
    case 401:
      throw formattedResponse as CustomErrorResponse;
    default:
      throw formattedResponse as CustomErrorResponse;
  }
};

export default Login;
