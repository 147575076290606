import {
  fetchAgentUrl,
  createNewAgentUrl,
  importUserGcpAgents,
  changeEnvironment,
  saveAndTrainAgentUrl,
  createMigrationUrl,
  botCreationRetryUrl,
  getAgentChangeEnvUrl,
  deleteAgentUrl,
  checkDuplicateAgentUrl,
  deflectionUrl,
  platformPerformanceUrl,
  editAgentUrl,
} from "../../environment/environment.dev";
import { interceptor } from "../../middlewares/authenticatedFetch";
import responseParser from "../../middlewares/responseParser";
import {
  VirtualAgent,
  VirtualAgentState,
} from "../../state/reducers/virtual-agent/virtual-agent-reducer";
import ExtractAgentDetails, {
  SelectedAgentDetails,
} from "../utils/extractAgentDetails";

const listAllVirutalAgents = async (
  selectedAgent: SelectedAgentDetails
): Promise<VirtualAgent[]> => {
  // const environment =
  //   localStorage.getItem("ags_selected_environment") || "sandbox";
  // const selected_project =
  //   sessionStorage.getItem("ags_selected_project") || "{}";
  // const projectId = JSON.parse(selected_project).id;

  const { environment, project_id } = selectedAgent;

  // const fetchAgentUrlModified = projectId
  //   ? `${fetchAgentUrl}?projectId=${projectId}&environment=${environment}`
  //   : `${fetchAgentUrl}?environment=${environment}`;
  const response = await interceptor(
    `${fetchAgentUrl}?environment=${environment}`
  );
  const result: { list: VirtualAgent[] } = await responseParser(response);
  const list  = result.list;

  if (list) {
    return list as VirtualAgent[];
  }
  return []
};

const createVirutalAgent = async (agent: VirtualAgent) => {
  // const selected_project = sessionStorage.getItem("ags_selected_project") || "{}";
  // const project = JSON.parse(selected_project);
  // const { id, project_type } = project;
  // const service_account_blob = await fetch(
  //   agent.project_info.service_account_file
  // );
  // const service_account_file = await service_account_blob.blob();

  // agent.project_info.service_account_file = "";

  let formdata = new FormData();

  for (const key in agent) {
    if (typeof agent[key as keyof VirtualAgent] === "object") {
      formdata.append(key, JSON.stringify(agent[key as keyof VirtualAgent]));
    } else {
      formdata.append(key, `${agent[key as keyof VirtualAgent]}`);
    }
  }

  // formdata.append("service_account_file", service_account_file);
  const response = await interceptor(`${createNewAgentUrl}`, {
    method: "POST",
    body: formdata,
    timeout: 10000,
  });
  const result: { msg: string; id: string } = await responseParser(response);
  // const id = new Date().getTime().toString()
  // const result= { msg: "Agent Created Succesfully!", id }
  return { result, agent: { ...agent, id: result.id } };
};

const importAgentsFromGcp = async () => {
  const selected_project =
    sessionStorage.getItem("ags_selected_project") || "{}";
  const projectId = JSON.parse(selected_project).id;
  const response = await interceptor(
    `${importUserGcpAgents}?projectId=${projectId}&redirectUrl=${process.env.REACT_APP_AGENTS_IMPORT_REDIRECT_URL}`
  );
  const result: { redirectUrl: string } = await responseParser(response);
  return result;
};

const changePlatformEnvironment = async (environment: string) => {
  const response = await interceptor(
    `${changeEnvironment}?environment=${environment}`,
    {
      method: "POST",
    }
  );
  const result = await responseParser(response);
  return result;
};

const saveAndTrainAgent = async (payload: any) => {
  const response = await interceptor(`${saveAndTrainAgentUrl}`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  });
  const result = await responseParser(response);
  return result;
};

const createMigration = async (payload: any) => {
  const response = await interceptor(`${createMigrationUrl}`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  });
  const result = await responseParser(response);
  return result;
};

const retryBotCreation = async (payload: any) => {
  const response = await interceptor(`${botCreationRetryUrl}`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  });
  const result = await responseParser(response);
  return result;
};

const getAgentForChangeEnv = async (id: string, environment: string) => {
  const response = await interceptor(
    `${getAgentChangeEnvUrl}?id=${id}&environment=${environment}`,
    {
      method: "POST",
    }
  );
  const result = await responseParser(response);
  return { ...result.agentData };
};
const updateAgent=async(id:string,name:string,description:string)=>{
  const response = await interceptor(
    `${editAgentUrl}`,
    {
      method: "PUT",
      body: JSON.stringify({bot_id:id,
        name:name,
        description: description
      }),
      headers: { "content-type": "application/json" },
    }
  );
  const result = await responseParser(response);
  return { ...result };
}
const deleteAgent = async (id: string) => {
  const response = await interceptor(`${deleteAgentUrl}?id=${id}`, {
    method: "DELETE",
  });
  const result = await responseParser(response);
  return result;
};

const checkAgentExists = async (name: string) => {
  const response = await interceptor(`${checkDuplicateAgentUrl}?name=${name}`, {
    method: "GET",
  });
  const result = await responseParser(response);
  return result;
};

const deflectionRate = async (
  virtualAgent: VirtualAgentState,
  environment: any
) => {
  const agents = virtualAgent.list;
  const compositeAgentIds = agents.map((agent) => {
    return agent.id + "__" + environment + "__" + agent.project_info.location;
  });
  const workspace = environment;
  const response = await interceptor(platformPerformanceUrl, {
    method: "POST",
    body: JSON.stringify({
      workspace,
      compositeAgentIds,
    }),
    headers: { "content-type": "application/json" },
  });
  const deflection_response = await interceptor(deflectionUrl, {
    method: "POST",
    body: JSON.stringify({
      workspace,
      compositeAgentIds,
    }),
    headers: { "content-type": "application/json" },
  });
  const result = await response.json();
  const deflection_result = await deflection_response.json();
  const botList = result.details.agentList;
  const platformPerformance = parseInt(result.details.platformPerformance).toFixed(1);
  const deflectionRate = deflection_result.details.deflectionRate;
  return { platformPerformance, deflectionRate,botList };
};

export {
  listAllVirutalAgents,
  createVirutalAgent,
  importAgentsFromGcp,
  changePlatformEnvironment,
  saveAndTrainAgent,
  createMigration,
  retryBotCreation,
  getAgentForChangeEnv,
  deleteAgent,
  checkAgentExists,
  deflectionRate,
  updateAgent,
};
