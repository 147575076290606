import { async } from "@firebase/util";
import { Details } from "@mui/icons-material";
import { createAsyncThunk } from "../../../middlewares/asyncThunk";
import {
  fetchWarehouseDomains,
  fetchWarehouseXML,
  fetchWarehouseDomainIntents,
  fetchWarehouseIntentUtterances,
  fetchWarehouseBots,
  fetchSkills,
  uploadBotPipeline,
  uploadSkillPipeline,
  fetchSkillByBotId,
  fetchIntentBySkillId,
  uploadBotCxPipeline,
  virtualAgentCxPipeline,
  searchData,
  skillImport,
  addIntentToVa,
  addPartnerAdmin,
  fetchAddPartnerData,
  fetchAddCustomerData,
  fetchAddUserData,
  addPartnerUserAdmin,
  updatePartnerUserAdmin,
  deletePartnerUserAdmin,
  fetchApplicationLogs,
  fetchAuditLogs,
  // fetchDashboardData,
  getCustomersForPartnerAdminPremier,
  fetchEntityTypeByBotId,
  fetchTotalGcpProjects,
  fetchTotalCustomers,
  fetchTotalPartners,
  fetchTotalIntents,
  fetchTotalSkills,
  fetchDashboardChartData,
  fetchExportLogs,
  fetchChartUsageData,
  fetchGcpProjects,
  addGcpProjects,
  verifyGcpProject,
  deleteGcpProject,
  getGcpProjectIds,
  fetchGcpProjectsForUser,
  resendMailGcpProject,
  responseFromNemoService,
  fetchUserGuideDoc,
  activateDemoModeService,
} from "../../../services/api/intent-warehouse-service";
import { WarehouseBot } from "./intent-warehouse-reducer";
import {
  IntentDeployModel,
  IVADeployModel,
  partnerAdminModel,
  partnerAdminUserModel,
} from "./library-interfaces";

export const fetchWarehouseDomainsEffectAction =
  "intent-warehouse/fetchWarehouseDomains";
export const fetchWarehouseXmlEffectAction = "intent-warehouse/getFlowData";
export const fetchWarehouseDomainIntentsEffectAction =
  "intent-warehouse/fetchWarehouseDomainIntents";
export const fetchPreferedDomainIntentsEffectAction =
  "intent-warehouse/fetchPreferedDomainIntents";
export const fetchWarehouseDomainWarehouseBotsEffectAction =
  "intent-warehouse/fetchWarehouseDomainWarehouseBots";
export const fetchWarehouseDomainSkillsEffectAction =
  "intent-warehouse/fetchWarehouseDomainSkills";
export const fetchWarehouseIntentUtterancesEffectAction =
  "intent-warehouse/fetchWarehouseIntentUtterances";
export const searchWarehouseIntentsEffectAction =
  "intent-warehouse/searchIntents";
export const uploadBotPipelineEffectAction =
  "intent-warehouse/uploadBotPipeline";
export const uploadSkillPipelineEffectAction =
  "intent-warehouse/uploadSkillPipeline";
export const uploadBotCxPipelineEffectAction =
  "intent-warehouse/uploadBotCxPipeline";
export const fetchSkillByBotIdEffectAction =
  "intent-warehouse/fetchSkillByBotId";
export const fetchIntentBySkillIdEffectAction =
  "intent-warehouse/fetchIntentBySkillId";
export const deployCXVAEffectAction = "intent-warehouse/deployCXAgent";
export const deploySkillCXVAEffectAction =
  "intent-warehouse/deploySkillCXAgent";
export const createIntentAction = "intent-warehouse/createIntent";
export const fetchEntityTypeByBotIdEffectAction =
  "intent-warehouse/fetchEntityTypeByBotId";
export const fetchGcpProjectsForUserEffectAction =
  "/intent-warehouse/getAllGcpProjectIds";
export const receiveResponseFromNemoLLMEffectAction =
  "/intent-warehouse/receiveNemoResponse";
export const userguideUrlEffectAction = "/intent-warehouse/userguideUrl";
export const fetchActivateDemoModeEffectAction =
  "/intent-warehouse/activate-demo-mode";

// Admin
export const addPartnerAdminEffectAction = "/intent-warehouse/addOrganization";
export const fetchAddPartnerDataEffectAction =
  "/intent-warehouse/getAddedPartnerData";
export const fetchAddCustomerDataEffectAction =
  "/intent-warehouse/getAddedCustomerData";

export const fetchAddUserDataEffectAction = "/intent-warehouse/getUserData";
export const addPartnerUserAdminEffectAction = "/intent-warehouse/createUser";
export const updatePartnerUserAdminEffectAction =
  "/intent-warehouse/updateUserData";
export const deletePartnerUserAdminEffectAction =
  "/intent-warehouse/deleteUserData";
//Usage Analytics
export const fetchApplicationLogsEffectAction = "/intent-warehouse/getAppLogs";
export const fetchAuditLogsEffectAction = "/intent-warehouse/getAuditLogs";
export const fetchExportLogsEffectAction = "/intent-warehouse/getExportLogs";
export const fetchUsageChartEffectAction = "/intent-warehouse/getUsageChart";
export const fetchGcpProjectIdsEffectAction =
  "/intent-warehouse/getGcpProjectsId";

//Dashboard
export const fetchDashboardDataEffectAction =
  "/intent-warehouse/getDashboardData";
export const getCustomerForPartnerAdminPremiumEffectAction =
  "/intent-warehouse/getCustomerDataPartnerAdminPremium";

export const fetchTotalGcpProjectsAction =
  "/intent-warehouse/getTotalgcpProjects";
export const fetchTotalPartnersEffectAction =
  "/intent-warehouse/getTotalPartners";
export const fetchTotalSkillsEffectAction = "/intent-warehouse/getTotalSkills";
export const fetchTotalCustomersEffectAction =
  "/intent-warehouse/getTotalCustomers";
export const fetchTotalIntentsEffectAction =
  "/intent-warehouse/getTotalIntents";
export const fetchDashboardChartDataEffectAction =
  "/intent-warehouse/getDashboardChartData";

// GCP Projects
export const fetchGcpProjectsEffectAction = "/intent-warehouse/getGcpProjects";
export const createGcpProjectsEffectAction =
  "/intent-warehouse/createGcpProjects";
export const verifyGcpProjectsEffectAction =
  "/intent-warehouse/verifyGcpProjects";
export const deleteGcpProjectsEffectAction =
  "/intent-warehouse/deleteGcpProjects";
export const resendMailForGcpProjectEffectAction =
  "/intent-warehouse/resendMailForGcpProject";

export const fetchWarehouseDomainsEffect = () =>
  createAsyncThunk(fetchWarehouseDomainsEffectAction, async function () {
    const warehouseDomainsData = await fetchWarehouseDomains();
    return warehouseDomainsData || [];
  });

export const fetchWarehouseXmlEffect = (
  skill_name: string,
  type: "bot" | "skill"
) =>
  createAsyncThunk(fetchWarehouseXmlEffectAction, async function () {
    const warehouseXMLData = await fetchWarehouseXML(skill_name, type);
    return warehouseXMLData || " ";
  });

export const uploadBotPipelineEffect = (
  details: {
    bot_name: string;
    bot_uri_es: string;
  },
  onCompleted: any
) =>
  createAsyncThunk(uploadBotPipelineEffectAction, async function () {
    const result = await uploadBotPipeline(details);
    onCompleted();
    return result;
  });

export const uploadSkillPipelineEffect = (
  details: {
    skill_name: string;
    bot_uri_cx: string;
  },
  onCompleted: any
) =>
  createAsyncThunk(uploadSkillPipelineEffectAction, async function () {
    const result = await uploadSkillPipeline(details);
    onCompleted();
    return result;
  });

export const uploadBotCxPipelineEffect = (
  details: {
    bot_name: string;
    bot_uri_cx: string;
  },
  onCompleted: any
) =>
  createAsyncThunk(uploadBotCxPipelineEffectAction, async function () {
    const result = await uploadBotCxPipeline(details);
    onCompleted();
    return result;
  });

export const fetchWarehouseDomainIntentsEffect = (domainIds?: string[]) =>
  createAsyncThunk(fetchWarehouseDomainIntentsEffectAction, async function () {
    const warehouseDomainIntentsData = await fetchWarehouseDomainIntents({
      domainIds,
    });
    return warehouseDomainIntentsData || [];
    // return []
  });

export const fetchPreferedDomainIntentsEffect = (domainIds?: string[]) =>
  createAsyncThunk(fetchPreferedDomainIntentsEffectAction, async function () {
    const warehouseDomainIntentsData = await fetchWarehouseDomainIntents({
      domainIds,
    });
    return warehouseDomainIntentsData || [];
    // return []
  });

// export const fetchAllWarehouseIntentsEffect = () =>
//   createAsyncThunk(fetchWarehouseDomainIntentsEffectAction, async function () {
//     const warehouseDomainIntentsData = await fetchWarehouseDomainIntents();
//     return warehouseDomainIntentsData;
//   });

export const fetchWarehouseDomainWarehouseBotsEffect = (
  domainId?: string[],
  doneAction?: any
) =>
  createAsyncThunk(
    fetchWarehouseDomainWarehouseBotsEffectAction,
    async function () {
      let warehouseDomainBotsData: WarehouseBot[] = await fetchWarehouseBots(
        {}
      );
      // warehouseDomainBotsData = warehouseDomainBotsData.map((_) => ({
      //   ..._,
      //   bot_description_short: botData[_.bot_name]?.["shortDesc"],
      //   bot_description_long: botData[_.bot_name]?.["longDesc"],
      //   bot_display_name:botData[_.bot_name]?.["displayName"],
      //   bot_url: botData[_.bot_name]?.["url"],
      //   number: botData[_.bot_name]?.["number"],
      //   languages: botData[_.bot_name]?.["lang"],
      //   guide: botData[_.bot_name]?.["guide"],
      // }));
      if (doneAction) doneAction();
      return warehouseDomainBotsData;
    }
  );

export const fetchWarehouseDomainSkillsEffect = (domainId?: string[]) =>
  createAsyncThunk(fetchWarehouseDomainSkillsEffectAction, async function () {
    const skillsData = await fetchSkills({});
    return skillsData.sort((a, b) => {
      return a.updated_at ? -1 : 1;
    });
  });

export const searchWarehouseIntentsEffect = (searchQuery: string) =>
  createAsyncThunk(searchWarehouseIntentsEffectAction, async function () {
    const warehouseSearchIntentsData = await fetchWarehouseDomainIntents({
      searchQuery,
    });
    return warehouseSearchIntentsData;
  });

export const fetchWarehouseIntentUtterancesEffect = (id: string) =>
  createAsyncThunk(
    fetchWarehouseIntentUtterancesEffectAction,
    async function () {
      const warehouseIntentUtterancesData =
        await fetchWarehouseIntentUtterances(id);
      return warehouseIntentUtterancesData;
    }
  );

export const fetchSkillByBotIdEffect = (id: string) =>
  createAsyncThunk(fetchSkillByBotIdEffectAction, async function () {
    const warehouseSelectedBotSkill = await fetchSkillByBotId(id);
    return warehouseSelectedBotSkill;
  });

export const fetchIntentBySkillIdEffect = (skillId: string, botId?: string) =>
  createAsyncThunk(fetchIntentBySkillIdEffectAction, async function () {
    const warehouseSelectedSkillIntent = await fetchIntentBySkillId(
      skillId,
      botId
    );
    return warehouseSelectedSkillIntent;
  });

export const deployVAEffect = (vaInfo: IVADeployModel, doneAction?: any) =>
  createAsyncThunk(deployCXVAEffectAction, async function () {
    const addCxAgent = await virtualAgentCxPipeline(vaInfo);
    if (doneAction) doneAction();
    return addCxAgent;
  });

export const deploySkillVAEffect = (vaInfo: IVADeployModel, doneAction?: any) =>
  createAsyncThunk(deploySkillCXVAEffectAction, async function () {
    const addCxAgent = await skillImport(vaInfo);
    if (doneAction) doneAction();
    return addCxAgent;
  });

export const searchDataEffectAction = "intent-warehouse/searchdata";

export const searchDataEffect = (searchQuery: string, type: string) =>
  createAsyncThunk(searchDataEffectAction, async function () {
    const seachResult = await searchData(searchQuery, type);
    return seachResult;
  });

export const uploadIntentPipelineEffect = (
  details: IntentDeployModel,
  onCompleted?: any
) =>
  createAsyncThunk(createIntentAction, async function () {
    const result = await addIntentToVa(details);
    onCompleted();
    return result;
  });

// Admin Dashboard Api
export const addPartnerAdminEffect = (
  partnerInfo: partnerAdminModel,
  onCompleted?: any
) => {
  return createAsyncThunk(addPartnerAdminEffectAction, async function () {
    const result = await addPartnerAdmin(partnerInfo);
    if (onCompleted) {
      onCompleted();
    }
    return result;
  });
};

export const addCustomerPartnerPermiumEffect = (
  partnerInfo: partnerAdminModel,
  onCompleted?: any
) => {
  return createAsyncThunk(addPartnerUserAdminEffectAction, async function () {
    const result = await addPartnerAdmin(partnerInfo);
    if (onCompleted) {
      onCompleted();
    }
    return result;
  });
};

export const fetchAddPartnerDataEffect = (params: unknown) => {
  return createAsyncThunk(fetchAddPartnerDataEffectAction, async function () {
    const result = await fetchAddPartnerData(params);
    return result;
  });
};

export const fetchAddCustomerDataEffect = (params: unknown) => {
  return createAsyncThunk(fetchAddCustomerDataEffectAction, async function () {
    const result = await fetchAddCustomerData(params);
    return result;
  });
};

export const fetchAdduserDataEffect = (params: unknown) => {
  return createAsyncThunk(fetchAddUserDataEffectAction, async function () {
    const result = await fetchAddUserData(params);
    return result;
  });
};

export const addPartnerAdminUserEffect = (
  partnerInfo: partnerAdminUserModel,
  onCompleted?: any
) => {
  return createAsyncThunk(addPartnerUserAdminEffectAction, async function () {
    const result = await addPartnerUserAdmin(partnerInfo);
    if (onCompleted) {
      onCompleted();
    }
    return result;
  });
};

export const updatePartnerAdminUserEffect = (
  partnerInfo: any,
  onCompleted?: any
) => {
  return createAsyncThunk(
    updatePartnerUserAdminEffectAction,
    async function () {
      const result = await updatePartnerUserAdmin(partnerInfo);
      if (onCompleted) {
        onCompleted();
      }
      return result;
    }
  );
};

export const deletePartnerAdminUserEffect = (
  partnerInfo: any,
  onCompleted?: any
) => {
  return createAsyncThunk(
    deletePartnerUserAdminEffectAction,
    async function () {
      const result = await deletePartnerUserAdmin(partnerInfo);
      if (onCompleted) {
        onCompleted();
      }
      return result;
    }
  );
};

export const fetchApplicationLogsEffect = (params: unknown) => {
  return createAsyncThunk(fetchApplicationLogsEffectAction, async function () {
    const result = await fetchApplicationLogs(params);
    return result;
  });
};

export const fetchAuditLogsEffect = (params: unknown) => {
  return createAsyncThunk(fetchAuditLogsEffectAction, async function () {
    const result = await fetchAuditLogs(params);
    return result;
  });
};

export const fetchExportLogsEffect = (params: unknown) => {
  return createAsyncThunk(fetchExportLogsEffectAction, async function () {
    const result = await fetchExportLogs(params);
    return result;
  });
};

export const fetchUsageChartEffect = (params: unknown) => {
  return createAsyncThunk(fetchUsageChartEffectAction, async function () {
    const result = await fetchChartUsageData(params);
    return result;
  });
};

export const fetchDashboardDataEffect = (params: unknown) => {
  return createAsyncThunk(fetchDashboardDataEffectAction, async function () {
    const result = await Promise.all([
      fetchTotalGcpProjects(params),
      fetchTotalCustomers(params),
      fetchTotalPartners(params),
      fetchTotalSkills(params),
      fetchTotalIntents(params),
      fetchDashboardChartData(params),
    ]);
    return result;
  });
};

export const getCustomersForPartnerAdminPremiumEffect = (params: any) => {
  return createAsyncThunk(
    getCustomerForPartnerAdminPremiumEffectAction,
    async function () {
      const result = await getCustomersForPartnerAdminPremier(params);
      return result;
    }
  );
};

// Dashboard
export const getDashboardChartDataEffect = (params: any) => {
  return createAsyncThunk(
    fetchDashboardChartDataEffectAction,
    async function () {
      const result = await fetchDashboardChartData(params);
      return result;
    }
  );
};
export const getTotalgcpProjectsEffect = (params: any) => {
  return createAsyncThunk(fetchTotalGcpProjectsAction, async function () {
    const result = await fetchTotalGcpProjects(params);
    return result;
  });
};
export const getTotalCustomersEffect = (params: any) => {
  return createAsyncThunk(fetchTotalCustomersEffectAction, async function () {
    const result = await fetchTotalCustomers(params);
    return result;
  });
};
export const getTotalPartnersEffect = (params: any) => {
  return createAsyncThunk(fetchTotalPartnersEffectAction, async function () {
    const result = await fetchTotalPartners(params);
    return result;
  });
};
export const getTotalSkillsEffect = (params: any) => {
  return createAsyncThunk(fetchTotalSkillsEffectAction, async function () {
    const result = await fetchTotalSkills(params);
    return result;
  });
};
export const getTotalIntentsEffect = (params: any) => {
  return createAsyncThunk(fetchTotalIntentsEffectAction, async function () {
    const result = await fetchTotalIntents(params);
    return result;
  });
};

export const fetchEntityTypeByBotIdEffect = (id: string) =>
  createAsyncThunk(fetchEntityTypeByBotIdEffectAction, async function () {
    const etResult = await fetchEntityTypeByBotId(id);
    return etResult;
  });

export const fetchGcpProjectsEffect = (params: unknown) => {
  return createAsyncThunk(fetchGcpProjectsEffectAction, async function () {
    const result = await fetchGcpProjects(params);
    return result;
  });
};

export const addGcpProjectsEffect = (params: unknown, onCompleted?: any) => {
  return createAsyncThunk(createGcpProjectsEffectAction, async function () {
    const result = await addGcpProjects(params);
    if (onCompleted) onCompleted();
    return result;
  });
};

export const verifyGcpProjectEffect = (
  projectId: string,
  onCompleted?: any
) => {
  return createAsyncThunk(verifyGcpProjectsEffectAction, async function () {
    const result = await verifyGcpProject(projectId);
    if (onCompleted) onCompleted();
    return result;
  });
};

export const resendMailForGcpProjectEffect = (
  projectId: string,
  onCompleted?: any
) => {
  return createAsyncThunk(
    resendMailForGcpProjectEffectAction,
    async function () {
      const result = await resendMailGcpProject(projectId);
      if (onCompleted) onCompleted();
      return result;
    }
  );
};

export const deleteGcpProjectEffect = (
  project_id: string,
  onCompleted?: any
) => {
  return createAsyncThunk(deleteGcpProjectsEffectAction, async function () {
    const result = await deleteGcpProject(project_id);
    if (onCompleted) onCompleted();
    return result;
  });
};

export const getGcpProjectIdsEffect = (params: unknown, onCompleted?: any) => {
  return createAsyncThunk(fetchGcpProjectIdsEffectAction, async function () {
    const result: any = await getGcpProjectIds(params);
    if (onCompleted) onCompleted();
    return result;
  });
};

export const fetchGcpProjectsForUserEffect = (
  params: unknown,
  onCompleted?: any
) => {
  return createAsyncThunk(
    fetchGcpProjectsForUserEffectAction,
    async function () {
      const result: any = await fetchGcpProjectsForUser(params);
      if (onCompleted) onCompleted();
      return result;
    }
  );
};

export const fetchUserguideEffect = (params: unknown) => {
  return createAsyncThunk(userguideUrlEffectAction, async function () {
    const result: any = await fetchUserGuideDoc(params);
    return result;
  });
};

export const fetchActivateDemoModeEffect = () => {
  return createAsyncThunk(fetchActivateDemoModeEffectAction, async function () {
    const result: any = await activateDemoModeService();
    return result;
  });
};
