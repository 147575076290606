import { combineReducers } from "../utils/combine-reducer";
import appSlice from "./reducers/app/app-reducer";
import loginSlice from "./reducers/login/login-reducer";
import viralAgentSlice from "./reducers/virtual-agent/virtual-agent-reducer";
import usersSlice from "./reducers/users/users-reducer";
import loggingSlice from "./reducers/logging/logging-reducer";
// import warehouseSlice from "./reducers/warehouse/warehouse-reducer";
import intentWarehouseSlice from "./reducers/library/intent-warehouse-reducer";
import dashboardWidgetReducer from "./reducers/dashboard/dashboard.reducer";

const rootState = {
  [appSlice.sliceName]: appSlice.initialState,
  [loginSlice.sliceName]: loginSlice.initialState,
  [viralAgentSlice.sliceName]: viralAgentSlice.initialState,
  [usersSlice.sliceName]: usersSlice.initialState,
  [dashboardWidgetReducer.sliceName]: dashboardWidgetReducer.initialState,
  [loggingSlice.sliceName]: loggingSlice.initialState,
  [intentWarehouseSlice.sliceName]: intentWarehouseSlice.initialState,
};

const log =
  process.env.REACT_APP_ENVIRONMENT === "development" ||
  window.location.hostname === "localhost"
    ? true
    : false;

const rootReducer = combineReducers(
  {
    [appSlice.sliceName]: appSlice.reducer,
    [loginSlice.sliceName]: loginSlice.reducer,
    [viralAgentSlice.sliceName]: viralAgentSlice.reducer,
    [usersSlice.sliceName]: usersSlice.reducer,
    [dashboardWidgetReducer.sliceName]: dashboardWidgetReducer.reducer,
    [loggingSlice.sliceName]: loggingSlice.reducer,
    [intentWarehouseSlice.sliceName]: intentWarehouseSlice.reducer,
  },
  { log }
);

const store = {
  rootState,
  rootReducer,
};

export default store;
