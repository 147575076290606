import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import { useHistory, useLocation } from "react-router-dom";

import AnimatedRoute from "../../components/animated-route/animated-route";

import LoginBannerImage from "./../../assets/Login_Bg.png";
import LoginFooterImage from "./../../assets/login_footer.png";
import QollectiveLogo from "./../../assets/Qollective.cx.svg";
import { Box, Link, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

import LoginOne from "../login-one/login-one";
import LoginTwo from "../login-two/login-two";

export default function LoginContainer() {
  const history = useHistory();
  const location = useLocation();

  function redirectToLogin() {
    history.push("/login/2");
  }

  const { t } = useTranslation();

  return (
    <Grid direction="row" container height="100%">
      <Hidden mdDown>
        <Grid item md={6} lg={6} height="100%">
          <img
            width="100%"
            style={{ height: "inherit" }}
            src={LoginBannerImage}
            alt="Login"
          />
          <Box
            top="11vh"
            position="absolute"
            display="flex"
            alignItems="center"
            width="50%"
            justifyContent="center"
            flexDirection="column"
          >
            <Box width="37vw">
              <img
                style={{ height: "inherit", width: "inherit" }}
                src={QollectiveLogo}
                alt="Login"
              />
            </Box>
            {/* <Typography color="#fff" fontSize="1.375rem" textAlign="center" mt={2} mx="4rem">
              An AI communications as a service platform (AICaaS) that takes an
              industry focused approach to unify Customer, Employee and Agent
              experience with a conversational AI studio, an integration
              platform and a unified communications platform.”
            </Typography> */}
          </Box>
        </Grid>
      </Hidden>
      <Grid item flex="1" md={6} lg={6} height="100%">
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingInline: 2,
            height: "100%",
          }}
        >
          {/* {location.pathname === "/login/2" && (
            <Box mt={2} onClick={redirectToLogin}>
              <Link
                variant="body1"
                color="primary"
                sx={{ display: "flex", alignItems: "center" }}
                underline="hover"
              >
                <ArrowBackIcon
                  fontSize="small"
                  color="inherit"
                  sx={{ mx: 1 }}
                />
                {t("back")}
              </Link>
            </Box>
          )} */}
          {/* <AnimatedRoute
            timeout={1000}
            animationClassName="page"
            key="/login/2"
            path="/login/2"
            exact
            children={<LoginTwo />}
          /> */}
          <AnimatedRoute
            timeout={1000}
            animationClassName="page"
            key="/login"
            path="/login/1"
            exact
            children={<LoginTwo />}
          />
          <img
            onClick={redirectToLogin}
            style={{ marginBottom: 16, width: 120 }}
            src={LoginFooterImage}
            alt="Powered By Q"
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
