export const serverUrl = process.env.REACT_APP_SERVER_URL;
// export const serverUrl = "http://localhost:3001";
export const devUrl = "http://localhost";

// Logging api
export const createLoggerUrl = `${serverUrl}/intent-warehouse-api/logger`;
export const googleAuthUrl = `${serverUrl}/intent-warehouse-api/login`;
export const forgotPasswordUrl = `${serverUrl}/api/v1/user/forgot-password`;

//intent warehouse urls
export const fetchDomainsUrl = `${serverUrl}/intent-warehouse-api/getDomains`;
export const fetchXmlUrl = `${serverUrl}/intent-warehouse-api/getFlowData`;
export const fetchBotXmlUrl = `${serverUrl}/intent-warehouse-api/getBotFlowData`;
export const fetchIntentsUrl = `${serverUrl}/intent-warehouse-api/getIntents`;
export const fetchBotsUrl = `${serverUrl}/intent-warehouse-api/getBotTemplates`;
export const fetchSkillsUrl = `${serverUrl}/intent-warehouse-api/getSkills`;
export const fetchUtterancesUrl = `${serverUrl}/intent-warehouse-api/getUtteranceWithEntity`;
export const fetchSkillsByBotIdUrl = `${serverUrl}/intent-warehouse-api/getSkillByBotID`;
export const fetchIntentBySkillIdUrl = `${serverUrl}/intent-warehouse-api/getIntentByBotID`;
export const uploadWarehouseBotUrl = `${serverUrl}/api/v1/warehouse-bots`;
export const addCXAgenturl = `${serverUrl}/intent-warehouse-api/addCxAgent`;
export const addSkillCxurl = `${serverUrl}/intent-warehouse-api/addCxSkill`;
export const searchApiUrl = `${serverUrl}/intent-warehouse-api/searchData`;
export const createIntentUrl = `${serverUrl}/intent-warehouse-api/createIntent`;
export const fetchAppQueryLogsUrl = `${serverUrl}/intent-warehouse-api/getAppQuery`;
export const fetchAuditQueryLogsUrl = `${serverUrl}/intent-warehouse-api/getAuditQuery`;
export const fetchExportQueryLogsUrl = `${serverUrl}/intent-warehouse-api/getExportQUery`;
export const fetchUsageChartDataUrl = `${serverUrl}/intent-warehouse-api/getUsageChart`;
export const fetchGcpProjectsForUserUrl = `${serverUrl}/intent-warehouse-api/gcpProjectsId`;
export const fetchUserGuideUrl = `${serverUrl}/intent-warehouse-api/readUserGuide`;
export const activateDeoModeUrl = `${serverUrl}/intent-warehouse-api/activateDemoMode`;

// Interview VA APIs
export const receiveTextFromNemoLLMUrl = `${serverUrl}/intent-warehouse-api/receiveAnsFromLLM`;

// Admin Panel Dashboard screen
export const fetchTotalSkillsOnDashboardUrl = `${serverUrl}/intent-warehouse-api/getTotalSkills`;
export const fetchTotalIntentsUrl = `${serverUrl}/intent-warehouse-api/getTotalIntents`;
export const fetchTotalgcpProjectsUrl = `${serverUrl}/intent-warehouse-api/getTotalGcpProjects`;
export const fetchTotalCustomersUrl = `${serverUrl}/intent-warehouse-api/getTotalCustomers`;
export const fetchTotalPartnersUrl = `${serverUrl}/intent-warehouse-api/getTotalPartners`;
export const fetchDashboardChartDetailsUrl = `${serverUrl}/intent-warehouse-api/getChartDetails`;
// Admin-DashBoard  url
export const addPartnerUrl = `${serverUrl}/intent-warehouse-api/addOrganization`;
export const getAddedPartnerDataUrl = `${serverUrl}/intent-warehouse-api/getAddedPartnerData`;
export const getAddedCustomerDataUrl = `${serverUrl}/intent-warehouse-api/getAddedCustomerData`;
export const getCustomersForPartnerAdminPremiumDataUrl = `${serverUrl}/intent-warehouse-api/getCustomerData`;

export const fetchGcpProjectsUrl = `${serverUrl}/intent-warehouse-api/getGcpProjects`;
export const addGcpProjectsUrl = `${serverUrl}/intent-warehouse-api/createGcpProjects`;
export const verifyGcpProjectsUrl = `${serverUrl}/intent-warehouse-api/verifyGcpProjects`;
export const resendMailGcpProjectsUrl = `${serverUrl}/intent-warehouse-api/resendVerifyGcpProject`;
export const deleteGcpProjectsUrl = `${serverUrl}/intent-warehouse-api/deleteGcpProjects`;
export const fetchGcpProjectIdsUrl = `${serverUrl}/intent-warehouse-api/getGcpProjectsId`;

// users
export const getAddedUserDataUrl = `${serverUrl}/intent-warehouse-api/getUserData`;
export const addPartnerUserUrl = `${serverUrl}/intent-warehouse-api/createUser`;
export const updatePartnerUserUrl = `${serverUrl}/intent-warehouse-api/updateUserData`;
export const deletePartnerUserUrl = `${serverUrl}/intent-warehouse-api/deleteUserData`;
export const fetchEntityTypeByBotIdUrl = `${serverUrl}/intent-warehouse-api/getEntityTypeByBotID`;

/*--------------------------------- BELOW URL AND SERVICES NEEDS TO REMOVE -------------------------------------------- */

// agent studio core urls
export const serverLoginUrl = `${serverUrl}/api/v1/auth/login`;
export const fetchAgentUrl = `${serverUrl}/api/v1/virtual-agents/fetchAll`;
export const createNewAgentUrl = `${serverUrl}/api/v1/virtual-agents/create`;
export const fetchUserGcpProjects = `${serverUrl}/api/v1/auth/projects`;
export const importUserGcpProjects = `${serverUrl}/api/v1/auth/projects/connectGcpProjects`;
export const importUserGcpAgents = `${serverUrl}/api/v1/virtual-agents/syncGcpAgents`;
export const changeEnvironment = `${serverUrl}/api/v1/auth/login/switchEnvironment`;
export const saveAndTrainAgentUrl = `${serverUrl}/api/v1/deployment/publish`;
export const createMigrationUrl = `${serverUrl}/api/v1/migrate/create`;
export const botCreationRetryUrl = `${serverUrl}/api/v1/virtual-agents/retry`;
export const getAgentChangeEnvUrl = `${serverUrl}/api/v1/virtual-agents/getAgent`;
export const deleteAgentUrl = `${serverUrl}/api/v1/virtual-agents/delete`;
export const editAgentUrl = `${serverUrl}/api/v1/virtual-agents/edit`;
export const checkDuplicateAgentUrl = `${serverUrl}/api/v1/virtual-agents/checkAgentName`;

// Users urls

export const getAllUsersUrl = `${serverUrl}/api/v1/auth/register`;
export const resetPasswordUrl = `${serverUrl}/api/v1/auth/reset-password`;
// export const forgotPasswordUrl = `${serverUrl}/api/v1/auth/forgot-password`;
export const changePasswordUrl = `${serverUrl}/api/v1/auth/change-password`;
export const validateOTPUrl = `${serverUrl}/api/v1/auth/validate-otp`;

// Dashboard urls

export const dashboardCountUrl = `${serverUrl}/api/v1/dashboard-widget/getuserandconsumption`;

//agent assist urls
export const fetchConversationProfilesUrl = `${serverUrl}/api/v1/agent-assist/profiles`;
export const createConversationProfileUrl = `${serverUrl}/api/v1/agent-assist/profile`;
export const fetchKnowledgeBasesUrl = `${serverUrl}/api/v1/agent-assist/knowledge-bases`;
export const fetchKnowledgeBaseDocumentsUrl = `${serverUrl}/api/v1/agent-assist/knowledge-bases`;
export const createKnowledgeBaseUrl = `${serverUrl}/api/v1/agent-assist/knowledgebase`;
export const createKnowledgeBaseDocUrl = `${serverUrl}/api/v1/agent-assist/knowledgebasedocument`;
export const addKnowledgeBaseDocUrl = `${serverUrl}/api/v1/agent-assist/knowledgebasedocument/add`;
export const fetchKnowledgeBaseDocUrl = `${serverUrl}/api/v1/agent-assist/knowledge-bases-docs`;

//agent desktop urls
export const agentDesktopSettingsUrl = `${serverUrl}/api/v1/agent-desktop/getSettings`;
export const agentDesktopUpdateSettingsUrl = `${serverUrl}/api/v1/agent-desktop/updateSettings`;
export const agentStateResponseUrl = `${serverUrl}/api/v1/agent-desktop/getAgentStateResponses`;
export const agentStateResponseUpdateUrl = `${serverUrl}/api/v1/agent-desktop/updateAgentStateResponses`;
export const humanAgentsUrl = `${serverUrl}/api/v1/agent-desktop/humanAgent/list`;
export const createHumanAgentsUrl = `${serverUrl}/api/v1/agent-desktop/humanAgent/create`;
export const updateHumanAgentsUrl = `${serverUrl}/api/v1/agent-desktop/humanAgent/update`;
export const agentDesktopDashboardUrl = process.env.REACT_APP_AGENT_DESKTOP_URL;

// analytics urls
export const fetchLogsUrl = `${serverUrl}/api/v1/analytics/logs`;
export const fetchLookerDashboardUrl = `${serverUrl}/api/v1/analytics/fetchLookerDashboard`;

// integration hive urls
export const integartionHiveUrl = `${serverUrl}/api/v1/integration-hive`;
export const salesForceTablesUrl = `${serverUrl}/api/v1/integration-hive/getSalesForceTables`;
export const salesForceColumnsUrl = `${serverUrl}/api/v1/integration-hive/getSalesForceTableColumns`;
export const integrationHiveActionUrl = `${serverUrl}/api/v1/integration-hive/action`;
export const integrationHiveWebhookUrl = `${serverUrl}/api/v1/integration-hive/webhook`;
export const datasetsUrl = `${serverUrl}/api/v1/integration-hive/getDataSets`;
export const getStoresUrl = `${serverUrl}/api/v1/integration-hive/getStores`;
export const fetchUIParamsUrl = `${serverUrl}/api/v1/integration-hive/getFetchParams`;
export const fetchJSONDataUrl = `${serverUrl}/api/v1/integration-hive/testAction`;

//Intent Library urls
export const slotServerSlotsUrls = `${serverUrl}/api/v1/slot-server/slots`;
export const generateUtteranceUrls = `${serverUrl}/api/v1/paraphrases`;
export const getSlotsForMultipleQuery = `${serverUrl}/api/v1/slot-server/detectAllSlotsMulti`;
export const getSlotsForSingleQuery = `${serverUrl}/api/v1/slot-server/detectAllSlots`;

//Nlu settings urls
export const nluSettingsUrl = `${serverUrl}/api/v1/nlu/agent/`;

// conversation-designer apis
export const createNode = `${serverUrl}/api/v1/conversation-designer/nodes/createNode`;
export const fetchFlowsUrl = `${serverUrl}/api/v1/conversation-designer/flows/getAllFlows`;
export const createNewFlowUrl = `${serverUrl}/api/v1/conversation-designer/flows/createFlow`;
export const getFlowUrl = `${serverUrl}/api/v1/conversation-designer/flows/getFlow/`;
export const updateFlowUrl = `${serverUrl}/api/v1/conversation-designer/flows/updateFlow/`;
export const createNodeUrl = `${serverUrl}/api/v1/conversation-designer/nodes/createNode`;
export const getNodesByFlowIdUrl = `${serverUrl}/api/v1/conversation-designer/nodes/getNodesByFlowId`;
export const updateNodeUrl = `${serverUrl}/api/v1/conversation-designer/nodes/updateNode/`;
export const deleteNodeUrl = `${serverUrl}/api/v1/conversation-designer/nodes/delete/`;
export const deleteFlowByIDUrl = `${serverUrl}/api/v1/conversation-designer/flows/delete/`;
export const getEntityListUrl = `${serverUrl}/api/v1/conversation-designer/nodes/getEntityList`;
export const getNodesForLibUrl = `${serverUrl}/api/v1/conversation-designer/nodes/getNodesForLib`;
export const getNodeByIdUrl = `${serverUrl}/api/v1/conversation-designer/nodes/getNode/`;
export const createNodeFromLibUrl = `${serverUrl}/api/v1/conversation-designer/nodes/createNodeFromLib`;
export const deleteNodeFlowMappingUrl = `${serverUrl}/api/v1/conversation-designer/nodes/deleteNodeFlowMapping`;

// intent-library apis
export const getIntentLibraryUrl = `${serverUrl}/api/v1/nlu/getIntentDataByBotId`;
export const createIntentLibraryUrl = `${serverUrl}/api/v1/nlu/createIntent`;
export const deleteIntentLibraryUrl = `${serverUrl}/api/v1/nlu/deleteIntent`;
export const editIntentLibraryUrl = `${serverUrl}/api/v1/nlu/updateIntent`;
export const exportIntentLibraryUrl = `${serverUrl}/api/v1/nlu/exportIntent`;
export const importIntentLibraryUrl = `${serverUrl}/api/v1/nlu/importIntent`;
export const publishNativeModelUrl = `${serverUrl}/api/v1/nlu/addUpdateMultiIntent`;
// export const importIntentLibraryUrl = `https://cxt-nlu-lib-backend-dev.q-appliedai.com/api/v1/importIntent`

// entity library apis
export const getSystemEntityUrl = `${serverUrl}/api/v1/slot-server/getSystemEntity`;
export const getUserEntityUrl = `${serverUrl}/api/v1/nlu/getEntityDataByBotId`;
export const deleteUserEntityUrl = `${serverUrl}/api/v1/nlu/deleteEntity`;
export const createUserEntityUrl = `${serverUrl}/api/v1/nlu/createEntity`;
export const editUserEntityUrl = `${serverUrl}/api/v1/nlu/updateEntity/`;

// gateqeeper api
export const getQChatConfigUrl = `${serverUrl}/api/v1/web-chat/getGeneratedScript`;
export const saveQChatConfigUrl = `${serverUrl}/api/v1/web-chat/generate`;
export const integrateGateqeeperUrl = `${serverUrl}/api/v1/gate-qeeper/integrateGateQeeper`;
export const getconnectionDetailsUrl = `${serverUrl}/api/v1/gate-qeeper/getconnectionDetails/`;
export const deleteConnectionUrl = `${serverUrl}/api/v1/gate-qeeper/deleteConnectionDetail/`;
export const createLiveAgentUrl = `${serverUrl}/api/v1/gate-qeeper/createLiveAgent`;
export const getLiveAgentUrl = `${serverUrl}/api/v1/gate-qeeper/getLiveAgent`;
export const updateConnectionDetailUrl = `${serverUrl}/api/v1/gate-qeeper/updateConnectionDetail`;

// qnowledge repo apis
export const getQnowledgeReposUrl = `${serverUrl}/api/v1/knowledge-base/get_kbdata`;
export const readCSVforFAQUrl = `${serverUrl}/api/v1/knowledge-base/read_csv`;
export const createQnowledgeRepoUrl = `${serverUrl}/api/v1/knowledge-base/save_kbdata`;
export const editQnowledgeRepoUrl = `${serverUrl}/api/v1/knowledge-base/save_editdata`;
export const deleteQnowledgeRepoUrl = `${serverUrl}/api/v1/knowledge-base/del_kbdata`;
export const getQnowledgeRepoFAQUrl = `${serverUrl}/api/v1/knowledge-base/edit_kbdata`;
export const deleteQnowledgeRepoFAQUrl = `${serverUrl}/api/v1/knowledge-base/del_data_by_id`;

// optimization runs apis
export const getOptimizationRunsUrl = `${serverUrl}/api/v1/analytics/model`;
export const createOptimizationRunUrl = `${serverUrl}/api/v1/analytics/model`;
export const fetchAgentMetricsUrl = `${serverUrl}/api/v1/analytics/agent/metrics`;
export const fetchAgentDetailsUrl = `${serverUrl}/api/v1/analytics/agent/detailed`;
export const processModelUrl = `${serverUrl}/api/v1/analytics/model`;
export const updateModelUrl = `${serverUrl}/api/v1/analytics/model`;
export const publishByobModelUrl = `${serverUrl}/api/v1/analytics/model`;
export const fetchModelReportsUrl = `${serverUrl}/api/v1/analytics/model`;
export const deleteOptimizationRunUrl = `${serverUrl}/api/v1/analytics/model`;
export const optimizerAgentConfigurationUrl = `${serverUrl}/api/v1/sqrutinizer/update-agent`;
export const optimizerGetAgentConfigurationUrl = `${serverUrl}/api/v1/analytics/agent`;
export const fetchDatasetsUrl = `${serverUrl}/api/v1/analytics/dataset`;
export const deflectionUrl = `${serverUrl}/api/v1/analytics/agent/deflection-rate`;
export const platformPerformanceUrl = `${serverUrl}/api/v1/analytics/agent/performance`;
