import { Typography } from "@mui/material";
import { Suspense } from "react";
import { Route, RouteProps } from "react-router";
import { CSSTransition } from "react-transition-group";
import CubeLoader from "../q-cube-loader";

export interface QLazyRouteInterface extends RouteProps {
  animationClassName?: string;
  timeout: number;
}

export default function QLazyRoute(props: QLazyRouteInterface) {
  const { render, children, timeout, animationClassName, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense
          fallback={
            // <Typography
            //   variant={"h6"}
            //   style={{ position: "absolute", top: "50%", left: "50%" }}
            // >
                <CubeLoader />
              // {/* loading... */}
            // </Typography>
          }
        >
          <CSSTransition timeout={timeout} classNames={animationClassName}>
           <>{children}</>
          </CSSTransition>
        </Suspense>
      )}
    />
  );
}
