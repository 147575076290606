import { USER_LOGIN_ACTION } from "../login/login-reducer";
import {
  changeEnvironmentEffectAction,
  createBotRetryEffectAction,
  createMigrationEffectAction,
  createVirtualAgentEffectAction,
  listAllVirtualAgentEffectAction,
  saveAndTrainEffectAction,
  getAgentchangeEnvironmentEffectAction,
  deleteAgentEffectAction,
  duplicateAgentEffectAction,
  deflectionRateEffectAction,
  updateAgentEffectAction,
} from "./virtual-agent-effect";

const sliceName = "virtualAgent";

interface User {
  email: string | null;
  role: string | null;
}

export interface VirtualAgentProjectInfo {
  is_imported: boolean;
  sub_type: string;
  type: string;
  agent_id?: string;
  id?: string;
  analytics_enabled?: boolean;
  cxt_id?: string;
  deployment?: {
    hasDeployed: boolean;
    hasFailed: boolean;
    inProgress: boolean;
  };
  migration?: {
    hasFailed: boolean;
    hasMigrated: boolean;
    inProgress: boolean;
  };
  bot_creation?: string;
  location?: string;
  sandbox?: string;
  live?: string;
  dfAgentMapping: string;
}

export interface VirtualAgent {
  name: string;
  id: string;
  rate: number;
  displayName: string;
  location: string;
  environment: string;
  version: string;
  description: string;
  selected_industry: { id: string; name: string } | null;
  agent_assist_enabled: boolean;
  analytics_enabled: boolean;
  optimizer_enabled: boolean;
  users: User[];
  template_info: {
    template_name: string;
    template_id: string;
    enabled: boolean;
  };
  status: {
    message: string;
    code: 0 | 1 | 2;
  };
  organization: string;
  performance: number;
  project_info: VirtualAgentProjectInfo;
  migration_status: {
    cd: string;
    nlu: string;
  };
  deployment_status: {
    sandbox: {
      cd: string;
      nlu: string;
      intent: string;
    };
    live: {
      cd: string;
      nlu: string;
      intent: string;
    };
    deployed_at: number;
  };
  bot_creation_status: {
    status: string;
    sandbox: {
      agent: boolean;
      ccaiApi: boolean;
      dfApi: boolean;
      dfEnvironment: boolean;
      gcp: boolean;
    };
    live: {
      agent: boolean;
      ccaiApi: boolean;
      dfApi: boolean;
      dfEnvironment: boolean;
      gcp: boolean;
    };
    analyticsAgent: boolean;
  };
  sandbox?: {
    id: string;
    agent_id: string;
  };
  live?: {
    id: string;
    agent_id: string;
  };
  retry?: boolean;
}
export interface VirtualAgentState {
  industries_list: string[];
  deflection_rate_list: { botId: string; rate: number }[];
  deflection_rate_loader: boolean;
  platformPerformance: number;
  deflectionRate: number;
  new_agent: {
    model: VirtualAgent | Partial<VirtualAgent>;
    loading: boolean;
    error: boolean;
    is_duplicate: boolean | null;
    is_agent_creation_successful: boolean;
  };
  open_new_agent_dialog: boolean;
  list: VirtualAgent[];
  loading: boolean;
  selectedAgent?:
  | ((VirtualAgentProjectInfo & { name: string; botCreation?: any }) & {
    selected_industry: {
      id: string;
      name: string;
    };
  })
  | null;
  saved: boolean;
  environment: string;
  overlay: {
    loading: boolean;
    text: string;
  };
  query?: string;
  filteredVirtualAgentsList: VirtualAgent[];
  successVirtualAgentsList: VirtualAgent[];
  failedVirtualAgentsList: VirtualAgent[];
}

const selected_agent = localStorage.getItem("selected_agent") || "null";

// console.log(window.location.search.match(/environment=.*/g));

const [match] = window.location.search.match(/environment=.*/g) || [
  "environment=sandbox",
];
const [, environment] = match.split("=");

const isValidEnv = ["sandbox", "live"].indexOf(environment);
let ags_selected_environment = "sandbox";
if (isValidEnv !== -1) {
  ags_selected_environment = environment.length > 0 ? environment : "sandbox";
} else {
  console.log(window.location.search);
  const href =
    window.location.origin +
    "/dashboard/overview?" +
    // window.location.pathname +
    window.location.search.replace(
      // /environment=.*/,
      /.*/,
      `environment=sandbox`
    );

  window.open(href, "_self");
}

// console.log(ags_selected_environment);

// localStorage.setItem("ags_selected_environment", ags_selected_environment);

const initialState: VirtualAgentState = {
  deflection_rate_list: [],
  deflection_rate_loader: false,
  platformPerformance: 0,
  deflectionRate: 0,
  industries_list: [
    "Entertainment",
    "Technology",
    "HealthCare",
    "Financial Services",
    "Telecom",
    "Education",
    "Transport",
  ],
  new_agent: {
    model: {
      template_info: {
        template_name: "q-health-care",
        template_id: "q-template-id",
        enabled: true,
      },
      users: [],
      status: {
        message: "disabled",
        code: 0,
      },
      selected_industry: null,
      agent_assist_enabled: false,
      analytics_enabled: false,
      optimizer_enabled: false,
      organization: "quantiphi",
      project_info: {
        is_imported: false,
        sub_type: "es",
        type: "gcp",
        dfAgentMapping: "",
      },
      migration_status: {
        cd: "",
        nlu: "",
      },
      deployment_status: {
        sandbox: {
          cd: "",
          nlu: "",
          intent: "",
        },
        live: {
          cd: "",
          nlu: "",
          intent: "",
        },
        deployed_at: new Date().getTime(),
      },
      bot_creation_status: {
        analyticsAgent: false,
        sandbox: {
          agent: false,
          ccaiApi: false,
          dfApi: false,
          dfEnvironment: false,
          gcp: false,
        },
        live: {
          agent: false,
          ccaiApi: false,
          dfApi: false,
          dfEnvironment: false,
          gcp: false,
        },
        status: "pending",
      },
    },
    loading: false,
    error: true,
    is_duplicate: null,
    is_agent_creation_successful: false,
  },
  open_new_agent_dialog: false,
  list: [],
  loading: false,
  selectedAgent: null,
  overlay: {
    loading: false,
    text: "",
  },
  environment: ags_selected_environment,
  saved: false,
  filteredVirtualAgentsList: [],
  successVirtualAgentsList: [],
  failedVirtualAgentsList: [],
};

function reducer(
  state = initialState,
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case "virtualAgent/openModal":
      return {
        ...state,
        open_new_agent_dialog: true,
      };
    case "virtualAgent/searchAgent":
      let keyword = action.payload;
      let filteredList = state.filteredVirtualAgentsList.filter(
        (_) =>
          _.displayName.toLocaleLowerCase().includes(keyword) ||
          _.description.toLocaleLowerCase().includes(keyword)
      );
      const successAgentList: VirtualAgent[] = [];
      const failedAgentList: VirtualAgent[] = [];
      for (let index = 0; index < filteredList.length; index++) {
        const projectInfo = filteredList[index].project_info;
        if (projectInfo.bot_creation?.includes("success")) {
          successAgentList.push(filteredList[index]);
        }
        if (projectInfo.bot_creation?.includes("failed")) {
          failedAgentList.push(filteredList[index]);
        }
      }
      return {
        ...state,
        list: filteredList,
        successVirtualAgentsList: successAgentList,
        failedVirtualAgentsList: failedAgentList,
      };
    case "virtualAgent/filterBots":
      let filterList: VirtualAgent[] = [];
      if (action.payload && action.payload === "success") {
        filterList = state.successVirtualAgentsList;
      }
      if (action.payload && action.payload === "failed") {
        filterList = state.failedVirtualAgentsList;
      }
      if (action.payload === null) {
        filterList = filterList.concat(
          state.successVirtualAgentsList,
          state.failedVirtualAgentsList
        );
      }
      return {
        ...state,
        list: filterList,
      };
    case "virtualAgent/closeModal":
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          is_duplicate: null,
          is_agent_creation_successful: false,
          model: {
            ...initialState.new_agent.model,
          },
          error: true,
        },
        open_new_agent_dialog: false,
      };
    case "virtualAgent/onboarding":
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          model: {
            ...state.new_agent.model,
            ...action.payload,
          },
        },
      };
    case "virtualAgent/importBotDetails":
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          model: {
            ...state.new_agent.model,
            project_info: {
              ...state.new_agent.model.project_info,
              ...action.payload,
            },
          },
        },
      };
    case "virtualAgent/projectDetails":
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          model: {
            ...state.new_agent.model,
            sandbox: {
              ...state.new_agent.model.sandbox,
              ...action.payload.sandbox,
            },
            live: {
              ...state.new_agent.model.live,
              ...action.payload.live,
            },
          },
        },
      };
    case "virtualAgent/storeServiceAccountJson":
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          model: {
            ...state.new_agent.model,
            project_info: {
              ...state.new_agent.model.project_info,
              service_account_file: action.payload,
            },
          },
        },
      };
    case `${createVirtualAgentEffectAction}-pending`:
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          loading: true,
        },
      };
    case `${createVirtualAgentEffectAction}-completed`:
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          loading: false,
          is_agent_creation_successful: true,
        },
        list: [action.payload.agent, ...state.list],
      };
    case `${createVirtualAgentEffectAction}-rejected`:
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          loading: false,
          is_agent_creation_successful: false,
        },
      };
    case `${deflectionRateEffectAction}-pending`:
      return {
        ...state,
        deflection_rate_loader: true,
      };
    case `${deflectionRateEffectAction}-rejected`:
      return {
        ...state,
        deflection_rate_loader: false,
      };
    case `${deflectionRateEffectAction}-completed`:
      return {
        ...state,
        deflection_rate_list: action.payload.botList,
        platformPerformance: action.payload.platformPerformance,
        deflectionRate: action.payload.deflectionRate,
        deflection_rate_loader: false,
      };
    case `${listAllVirtualAgentEffectAction}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${listAllVirtualAgentEffectAction}-rejected`:
      return {
        ...state,
        loading: false,
      };
    case `${listAllVirtualAgentEffectAction}-completed`:
      const successList: VirtualAgent[] = [];
      const failedList: VirtualAgent[] = [];
      for (let index = 0; index < action.payload.length; index++) {
        const projectInfo = action.payload[index].project_info;
        if (projectInfo.bot_creation?.includes("success")) {
          successList.push(action.payload[index]);
        }
        if (projectInfo.bot_creation?.includes("failed")) {
          failedList.push(action.payload[index]);
        }
      }
      return {
        ...state,
        list: action.payload,
        filteredVirtualAgentsList: action.payload,
        successVirtualAgentsList: successList,
        failedVirtualAgentsList: failedList,
        loading: false,
      };
    case "virtualAgent/resetNewAgent":
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          is_agent_creation_successful: false,
          model: null,
        },
      };
    case "virtualAgent/setError":
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          error: action.payload,
        },
      };
    case "virtualAgent/setDuplicateError":
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          is_duplicate: action.payload,
        },
      };
    case "virtualAgent/saveChanges":
      return {
        ...state,
        saved: true,
      };
    case "virtualAgent/changeEnv":
      return {
        ...state,
        environment: action.payload,
      };
    case "virtualAgent/selectedAgent":
      return {
        ...state,
        selectedAgent: action.payload,
      };
    case "virtualAgent/enableModule":
      const index = state.list.findIndex(
        (_) => _.displayName === action.payload.name
      );
      const updatedVA = {
        ...state.list[index],
        [action.payload.service]: true,
      };
      return {
        ...state,
        list: state.list
          .filter((_) => _.displayName !== action.payload.name)
          .concat([updatedVA]),
      };
    case `${USER_LOGIN_ACTION}-completed`:
      return {
        ...state,
        environment: action.payload.last_accessed_environment,
      };
    case `${changeEnvironmentEffectAction}-pending`:
      return {
        ...state,
        overlay: {
          loading: true,
          text: "Switching Environment...Please Wait...",
        },
      };
    case `${changeEnvironmentEffectAction}-rejected`:
      return {
        ...state,
        overlay: {
          loading: false,
          text: "",
        },
      };
    case `${saveAndTrainEffectAction}-pending`:
      return {
        ...state,
        overlay: {
          ...state.overlay,
          loading: true,
          text: "Creating Save And Train Job...Please Wait...",
        },
      };
    case `${saveAndTrainEffectAction}-completed`:
      return {
        ...state,
        overlay: {
          ...state.overlay,
          loading: false,
          text: "",
        },
      };
    case `${saveAndTrainEffectAction}-rejected`:
      return {
        ...state,
        overlay: {
          ...state.overlay,
          loading: false,
          text: "",
        },
      };
    case "virtualAgent/resetSelectedAgent":
      return {
        ...state,
        selectedAgent: undefined,
      };
    case "virtualAgent/updateAgentStatus": {
      if (state.environment === action.payload.environment) {
        if (
          state.selectedAgent &&
          state.selectedAgent.cxt_id === action.payload.cxt_id
        ) {
          return {
            ...state,
            selectedAgent: {
              ...state.selectedAgent,
              deployment: {
                ...state.selectedAgent.deployment,
                ...action.payload.deployment,
              },
            },
          };
        }

        state.list.forEach((_) => {
          if (_.id === action.payload.name) {
            _.project_info = {
              ..._.project_info,
              deployment: {
                ..._.project_info.deployment,
                ...action.payload.deployment,
              },
            };
          }
        });
      }

      return {
        ...state,
      };
    }
    case `${createMigrationEffectAction}-pending`:
      return {
        ...state,
        overlay: {
          ...state.overlay,
          loading: true,
          text: "Creating  Job...Please Wait...",
        },
      };
    case `${createMigrationEffectAction}-completed`:
      return {
        ...state,
        overlay: {
          ...state.overlay,
          loading: false,
          text: "",
        },
      };
    case `${createMigrationEffectAction}-rejected`:
      return {
        ...state,
        overlay: {
          ...state.overlay,
          loading: false,
          text: "",
        },
      };
    case "virtualAgent/updateMigrationStatus": {
      if (
        state.selectedAgent &&
        state.selectedAgent.cxt_id === action.payload.cxt_id
      ) {
        return {
          ...state,
          selectedAgent: {
            ...state.selectedAgent,
            migration: {
              ...state.selectedAgent.migration,
              ...action.payload.migration,
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case "virtualAgent/updateBDMStatus": {
      if (
        state.selectedAgent &&
        state.selectedAgent.cxt_id === action.payload.cxt_id
      ) {
        return {
          ...state,
          selectedAgent: {
            ...state.selectedAgent,
          },
        };
      }
      state.list.forEach((_) => {
        if (_.id === action.payload.cxt_id) {
          _.project_info = {
            ..._.project_info,
            ...action.payload.project_info,
            location: _.location,
            bot_creation: action.payload.bot_creation,
          };
        }
      });
      return {
        ...state,
      };
    }
    case `${createBotRetryEffectAction}-pending`:
      return {
        ...state,
        overlay: {
          ...state.overlay,
          loading: true,
          text: "",
        },
      };
    case `${createBotRetryEffectAction}-completed`:
      return {
        ...state,
        overlay: {
          ...state.overlay,
          loading: false,
          text: "",
        },
      };
    case `${createBotRetryEffectAction}-rejected`:
      return {
        ...state,
        overlay: {
          ...state.overlay,
          loading: false,
          text: "",
        },
      };
    case "virtualAgent/updateList":
      return {
        ...state,
        list: action.payload.list,
      };
    case `${getAgentchangeEnvironmentEffectAction}-pending`:
      return {
        ...state,
        overlay: {
          loading: false,
          text: "Switching Environment...Please Wait...",
        },
      };
    case `${getAgentchangeEnvironmentEffectAction}-completed`:
      return {
        ...state,
        selectedAgent: { ...action.payload },
        overlay: {
          loading: false,
          text: "",
        },
      };
    case `${getAgentchangeEnvironmentEffectAction}-rejected`:
      window.location.href = "/dashboard/overview";
      return {
        ...state,
        overlay: {
          loading: false,
          text: "",
        },
      };
    case "virtualAgent/searchVirtualAgent":
      return {
        ...state,
        query: action.payload,
      };
    case `${updateAgentEffectAction}-pending`:
      return {
        ...state,
        overlay: {
          loading: true,
          text: "",
        },
      };
    case `${updateAgentEffectAction}-completed`:
      return {
        ...state,
        list: state.list.filter(
          // eslint-disable-next-line array-callback-return
          (_) => {
            if (_.id !== action.payload.bot_id) {
              return _;
            }
          }
        ),
        overlay: {
          loading: false,
          text: "",
        },
      };
    case `${updateAgentEffectAction}-rejected`:
      return {
        ...state,
        overlay: {
          loading: false,
          text: "",
        },
      };

    case `${deleteAgentEffectAction}-pending`:
      return {
        ...state,
        overlay: {
          loading: true,
          text: "",
        },
      };
    case `${deleteAgentEffectAction}-completed`:
      return {
        ...state,
        list: state.list.filter(
          // eslint-disable-next-line array-callback-return
          (_) => {
            if (_.id !== action.payload.id) {
              return _;
            }
          }
        ),
        successVirtualAgentsList: state.successVirtualAgentsList.filter(
          // eslint-disable-next-line array-callback-return
          (_) => {
            if (_.id !== action.payload.id) {
              return _;
            }
          }
        ),
        failedVirtualAgentsList: state.failedVirtualAgentsList.filter(
          // eslint-disable-next-line array-callback-return
          (_) => {
            if (_.id !== action.payload.id) {
              return _;
            }
          }
        ),
        overlay: {
          loading: false,
          text: "",
        },
      };
    case `${deleteAgentEffectAction}-rejected`:
      return {
        ...state,
        overlay: {
          loading: false,
          text: "",
        },
      };
    case `${duplicateAgentEffectAction}-pending`:
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          loading: true,
        },
      };
    case `${duplicateAgentEffectAction}-completed`:
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          loading: false,
          is_duplicate: action.payload.duplicate_name,
        },
      };
    case `${duplicateAgentEffectAction}-rejected`:
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          loading: false,
        },
      };
    case "virtualAgent/clearImportBotDetails":
      if (state.new_agent.model.sandbox) {
        delete state.new_agent.model.sandbox;
      }
      if (state.new_agent.model.live) {
        delete state.new_agent.model.live;
      }
      return {
        ...state,
        new_agent: {
          ...state.new_agent,
          model: {
            ...state.new_agent.model,
            project_info: {
              ...state.new_agent.model.project_info,
              ...action.payload,
              live: "",
              sandbox: "",
            },
          },
        },
      };
    case "app/logOut":
      return {
        ...initialState,
      };
    case "app/logOut-pending": {
      return {
        ...state,
        overlay: {
          loading: true,
          text: "Logging You Out...Please wait...",
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
}

const virtualAgentSlice = {
  sliceName,
  initialState,
  reducer,
};

export default virtualAgentSlice;
