import classes from "./q-cube-loader.module.css";
export default function CubeLoader() {
  return (
    <div className={classes["container"]}>
      <div className={classes["scene"]}>
        <div className={classes["webpack-cube"]}>
          <div className={classes["outer-cube"]}>
            <div className={`${classes["face"]} ${classes["face-top"]}`}></div>
            <div
              className={`${classes["face"]} ${classes["face-bottom"]}`}
            ></div>
            <div className={`${classes["face"]} ${classes["face-left"]}`}></div>
            <div
              className={`${classes["face"]} ${classes["face-right"]}`}
            ></div>
            <div
              className={`${classes["face"]} ${classes["face-front"]}`}
            ></div>
            <div className={`${classes["face"]} ${classes["face-back"]}`}></div>
          </div>
          <div className={classes["inner-cube"]}>
            <div className={`${classes["face"]} ${classes["face-top"]}`}></div>
            <div
              className={`${classes["face"]} ${classes["face-bottom"]}`}
            ></div>
            <div className={`${classes["face"]} ${classes["face-left"]}`}></div>
            <div
              className={`${classes["face"]} ${classes["face-right"]}`}
            ></div>
            <div
              className={`${classes["face"]} ${classes["face-front"]}`}
            ></div>
            <div className={`${classes["face"]} ${classes["face-back"]}`}></div>
          </div>
        </div>
        {/* <div className={classes["shadows-outer-container"]}>
          <div className={classes["shadow-outer"]}></div>
        </div>
        <div className={classes["shadows-inner-container"]}>
          <div className={classes["shadow-inner"]}></div>
        </div> */}
      </div>
    </div>
  );
}
