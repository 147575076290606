import * as React from "react";
import ReactDOM from "react-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import App from "./app";
import { StoreProvider } from "./state/agent-studio-context";
import './i18n';
import ErrorBoundary from "./error-boundary";

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <StoreProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </StoreProvider>
  </StyledEngineProvider>,
  document.querySelector("#root")
);
