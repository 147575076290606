import { fetchUserCountEffectAction } from "./dashboard.effects";


const sliceName = "dashboardWidget";

export interface dashboardWidgetState {
  userCount: number;
  consumptions: number;
}


const initialState: dashboardWidgetState = {
  userCount: 0,
  consumptions: 0
};

function reducer(state: dashboardWidgetState, action: { type: string; payload?: any }) {
  switch (action.type) {
    case `${fetchUserCountEffectAction}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${fetchUserCountEffectAction}-completed`:
      return {
        ...state,
        userCount : action.payload?.userCount,
        consumptions : action.payload?.consumptions,
        loading: false,
      };
    case `${fetchUserCountEffectAction}-rejected`:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
}

const dashboardWidgetReducer = {
  sliceName,
  initialState,
  reducer,
};

export default dashboardWidgetReducer;
