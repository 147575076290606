import checkAgentDeploymentStatus from "./checkAgentDepoymentStatus";

export const createAsyncThunk = (
  type: string,
  asyncCallBack: (state: any, dispatch: any) => any,
  payload?: any
) =>
  async function (dispatch: any, state: any) {
    try {
      dispatch({ type: `${type}-pending`, payload });
      const response = await asyncCallBack(state, dispatch);
      dispatch({ type: `${type}-completed`, payload: response });
    } catch (e) {
      dispatch({ type: `${type}-rejected`, payload: e });
    }
  };

export const createAsyncThunkWithStatusCheck = (
  type: string,
  asyncCallBack: (state: any, dispatch: any) => any,
  payload?: any
) =>
  checkAgentDeploymentStatus(async function (dispatch: any, state: any) {
    try {
      dispatch({ type: `${type}-pending`, payload });
      const response = await asyncCallBack(state, dispatch);
      dispatch({ type: `${type}-completed`, payload: response });
    } catch (e) {
      dispatch({ type: `${type}-rejected`, payload: e });
    }
  });
