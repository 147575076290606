import {
  fetchAnalyticsUrlEffectAction,
  fetchLogsEffectAction,
} from "./logging-effects";

const sliceName = "logging";

export interface Log {
  turn_id: string;
  bot_id: string;
  channel_type: string;
  session_id: string;
  agent_id: string;
  query_text: string;
  response_text: string;
  intent_display_name: string;
  timestamp: { value: string };
  error_message: string;
  customer_user_id: string;
}

export interface LogState {
  type: string;
  list: Log[];
  count: number;
  loading: boolean;
  inlineLoading: boolean;
  analyticsUrl: string;
  iframeLoader: boolean;
  iframeError: boolean;
  pageNumber : number;
  pageLimit : number
}

const initialState: LogState = {
  type: "chat",
  list: [],
  count: 0,
  loading: false,
  inlineLoading: false,
  analyticsUrl: "",
  iframeLoader: false,
  iframeError: false,
  pageNumber: 0,
  pageLimit: 5
};

function reducer(state: LogState, action: { type: string; payload?: any }) {
  switch (action.type) {
    case `${fetchLogsEffectAction}-pending`:
      return {
        ...state,
        list: [],
        loading: true,
      };
    case `${fetchLogsEffectAction}-completed`:
      return {
        ...state,
        list: action.payload.list,
        count: action.payload.count,
        loading: false,
      };
    case `${fetchLogsEffectAction}-rejected`:
      return {
        ...state,
        list: [],
        loading: false,
      };

    case `${fetchAnalyticsUrlEffectAction}-pending`:
      return {
        ...state,
        iframeLoader: true,
        iframeError: false
      };
    case `${fetchAnalyticsUrlEffectAction}-completed`:
      return {
        ...state,
        analyticsUrl: action.payload,
        iframeLoader: false,
        iframeError: false
      };
    case `${fetchAnalyticsUrlEffectAction}-rejected`:
      return {
        ...state,
        analyticsUrl: "",
        iframeLoader: false,
        iframeError: true
      };
    case "logging/updateIframeLoader":
      return {
        ...state,
        iframeLoader: false,
      };
    case "logging/updatePageNumber":
      return {
        ...state,
        pageNumber: action.payload,
      };
    case "logging/updatePageLimit":
      return {
        ...state,
        pageLimit: action.payload,
      };
    case "logging/updateType":
      return {
        ...state,
        type: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

const loggingSlice = {
  initialState,
  sliceName,
  reducer,
};

export default loggingSlice;
