import { createAsyncThunk } from "../../../middlewares/asyncThunk";
import {
  createVirutalAgent,
  listAllVirutalAgents,
  importAgentsFromGcp,
  changePlatformEnvironment,
  saveAndTrainAgent,
  createMigration,
  retryBotCreation,
  getAgentForChangeEnv,
  deleteAgent,
  checkAgentExists,
  deflectionRate,
  updateAgent
} from "../../../services/api/virtual-agent-service";
import { VirtualAgent, VirtualAgentState } from "./virtual-agent-reducer";

import ExtractAgentDetails from "../../../services/utils/extractAgentDetails";

export const createVirtualAgentEffectAction = "virtualAgent/createAgent";

export const createVirtualAgentEffect = () =>
  createAsyncThunk(
    createVirtualAgentEffectAction,
    async function ({ virtualAgent }) {
      const { model } = virtualAgent.new_agent;
      const response = await createVirutalAgent(model as VirtualAgent);
      return {
        ...response,
        agent: { ...response.agent, displayName: response.agent.name },
      };
    }
  );

export const listAllVirtualAgentEffectAction = "virtualAgent/listAgents";

export const listVirtualAgentEffect = (environment?: string) =>
  createAsyncThunk(listAllVirtualAgentEffectAction, async function (state) {
    const virtualAgentState = state.virtualAgent as VirtualAgentState;

    const selectedAgent = ExtractAgentDetails(virtualAgentState);
    const response = await listAllVirutalAgents(selectedAgent);
    let response_deflection_rate;
    return response;
  });

// export const importAgentsFromGcpEffectAction = "virtualAgent/importGcpAgents";

// export const importAgentsFromGcpEffect = () =>
//   createAsyncThunk(importAgentsFromGcpEffectAction, async function () {
//     const response = await importAgentsFromGcp();
//     window.open(response.redirectUrl, "_self");
//     return response;
//   });

export const changeEnvironmentEffectAction = "virtualAgent/changeEnvironment";

export const changeEnvironmentEffect = (environment: string) =>
  createAsyncThunk(changeEnvironmentEffectAction, async function () {
    const href =
      window.location.origin +
      // "/dashboard/virtual-agent/dashboard" +
      window.location.pathname +
      window.location.search.replace(
        /environment.*/,
        // /.*/,
        `environment=${environment}`
      );
    localStorage.removeItem("selected_agent");
    const response = await changePlatformEnvironment(environment);
    localStorage.setItem("ags_selected_environment", environment);
    window.open(href, "_self");
    return response;
  });

export const saveAndTrainEffectAction = "virtualAgent/saveAndTrain";

export const saveAndTrainEffect = () =>
  createAsyncThunk(saveAndTrainEffectAction, async function (state) {
    const agent = state.virtualAgent as VirtualAgentState;
    const selectedAgent = agent.selectedAgent;

    if (selectedAgent) {
      const payload = {
        projectId: selectedAgent.id,
        agentId: selectedAgent.agent_id,
        botId: selectedAgent.cxt_id,
        environment: agent.environment,
        location: selectedAgent.location,
        displayName: selectedAgent.name,
        retry:
          selectedAgent &&
          selectedAgent.deployment &&
          selectedAgent.deployment.hasFailed
            ? selectedAgent.deployment.hasFailed
            : false,
      };
      // const response = await fetchUsers();
      const response = await saveAndTrainAgent(payload);
      return response;
    }
    return new Error("No Agent Seelcted");
  });

export const createMigrationEffectAction = "virtualAgent/createMigration";

export const createMigrationEffect = () =>
  createAsyncThunk(createMigrationEffectAction, async function (state) {
    const virtualAgentState = state.virtualAgent as VirtualAgentState;

    const selectedAgent = ExtractAgentDetails(virtualAgentState);
    if (selectedAgent) {
      const payload = {
        botId: selectedAgent.cxt_id,
      };
      const response = await createMigration(payload);
      return response;
    }
    return new Error("No Agent selected");
  });

export const createBotRetryEffectAction = "virtualAgent/retry";

export const createBotRetryEffect = (botId: string) =>
  createAsyncThunk(createBotRetryEffectAction, async function () {
    if (botId) {
      const response = await retryBotCreation({ bot_id: botId, retry: true });
      return response;
    }
    return new Error("No Agent selected");
  });

export const getAgentchangeEnvironmentEffectAction =
  "virtualAgent/getAgentchangeEnvironment";

export const getAgentChangeEnvEffect = (cxtId: string, environment: string) =>
  createAsyncThunk(getAgentchangeEnvironmentEffectAction, async function () {
    const response = await getAgentForChangeEnv(cxtId, environment);
    localStorage.setItem("selected_agent", JSON.stringify(response));
    return response;
  });

export const deleteAgentEffectAction = "virtualAgent/Deletion";
export const updateAgentEffectAction = "virtualAgent/Updation";

const listVirtualAgentEffectAction = listVirtualAgentEffect();

export const updateAgentEffect = (cxtId: string,name:string,description:string) =>
  createAsyncThunk(updateAgentEffectAction, async function (state, dispatch) {
    const response = await updateAgent(cxtId,name,description);
    dispatch(listVirtualAgentEffectAction(dispatch, state));
    return response;
  });
export const deleteAgentEffect = (cxtId: string) =>
  createAsyncThunk(deleteAgentEffectAction, async function () {
    const response = await deleteAgent(cxtId);
    return response;
  });

export const deflectionRateEffectAction = "virtualAgent/Deflection-Rate";

export const deflectionRateEffect = (environment: string) =>
  createAsyncThunk(deflectionRateEffectAction, async function (state) {
    const virtualAgentState = state.virtualAgent as VirtualAgentState;
    const response = await deflectionRate(virtualAgentState, environment);
    return response;
  });
export const duplicateAgentEffectAction = "virtualAgent/duplicateAgent";

export const duplicateAgentEffect = (name: string) =>
  createAsyncThunk(duplicateAgentEffectAction, async function () {
    const response = await checkAgentExists(name);
    return response;
  });
