import { createAsyncThunk } from "../../../middlewares/asyncThunk";
import { fetchGcpProjects, importGcpProjects } from "../../../services/api/app.service";

export const fetchGcpProjectsEffectAction = "app/fetchUserGcpProjects";
export const importGcpProjectsEffectAction = "app/importUserGcpProjects";

export const fetchGcpProjectsEffect = () =>
  createAsyncThunk(fetchGcpProjectsEffectAction, async function () {
    const response = await fetchGcpProjects();
    return response;
  });

export const importGcpProjectsEffect = () =>
  createAsyncThunk(importGcpProjectsEffectAction, async function () {
    const response = await importGcpProjects();
    // const response = await fetchGcpProjects();
    window.open(response.redirectUrl,"_self");
    // window.open(response.redirectUrl, "_self");
    return {};
  });
