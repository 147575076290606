import {
  addCXAgenturl,
  addSkillCxurl,
  fetchBotsUrl,
  fetchXmlUrl,
  fetchDomainsUrl,
  fetchIntentBySkillIdUrl,
  fetchIntentsUrl,
  fetchSkillsByBotIdUrl,
  fetchSkillsUrl,
  fetchUtterancesUrl,
  searchApiUrl,
  uploadWarehouseBotUrl,
  createIntentUrl,
  fetchBotXmlUrl,
  addPartnerUrl,
  getAddedPartnerDataUrl,
  getAddedCustomerDataUrl,
  getAddedUserDataUrl,
  addPartnerUserUrl,
  updatePartnerUserUrl,
  deletePartnerUserUrl,
  fetchAppQueryLogsUrl,
  fetchAuditQueryLogsUrl,
  getCustomersForPartnerAdminPremiumDataUrl,
  fetchEntityTypeByBotIdUrl,
  fetchTotalPartnersUrl,
  fetchTotalCustomersUrl,
  fetchTotalgcpProjectsUrl,
  fetchTotalIntentsUrl,
  fetchTotalSkillsOnDashboardUrl,
  fetchDashboardChartDetailsUrl,
  fetchExportQueryLogsUrl,
  fetchUsageChartDataUrl,
  fetchGcpProjectsUrl,
  addGcpProjectsUrl,
  verifyGcpProjectsUrl,
  resendMailGcpProjectsUrl,
  deleteGcpProjectsUrl,
  fetchGcpProjectIdsUrl,
  fetchGcpProjectsForUserUrl,
  receiveTextFromNemoLLMUrl,
  fetchUserGuideUrl,
  activateDeoModeUrl,
} from "../../environment/environment.dev";
import { interceptor } from "../../middlewares/authenticatedFetch";
import responseParser from "../../middlewares/responseParser";
import {
  Skill,
  WarehouseBot,
} from "../../state/reducers/library/intent-warehouse-reducer";
import {
  Domain,
  Intent,
  IntentDeployModel,
  IVADeployModel,
  partnerAdminModel,
  Utterance,
  partnerAdminUserModel,
} from "../../state/reducers/library/library-interfaces";
import { deleteAgent } from "./virtual-agent-service";

export const fetchWarehouseDomains = async () => {
  const response = await interceptor(fetchDomainsUrl);
  const result: { data: Domain[] } = await responseParser(response);
  return result.data;
};

export const fetchWarehouseXML = async (
  name: string,
  type: "bot" | "skill"
) => {
  let url = "";
  if (type === "bot") {
    url = `${fetchBotXmlUrl}/${name}`;
  } else {
    url = `${fetchXmlUrl}/${name}`;
  }
  const response = await interceptor(url, {
    method: "GET",
    headers: { "content-type": "application/json" },
  });
  const result: { data: { xml_string: string } } = await responseParser(
    response
  );
  return result.data;
};

export const fetchWarehouseDomainIntents = async ({
  domainIds,
  searchQuery,
}: {
  domainIds?: string[];
  searchQuery?: string;
}) => {
  const fetchIntentsModifiedUrl =
    fetchIntentsUrl + (searchQuery ? `?intentName=${searchQuery}` : "");
  const response = await interceptor(fetchIntentsModifiedUrl, {
    method: "POST",
    body: JSON.stringify({
      domainIds: domainIds || [],
    }),
    headers: { "content-type": "application/json" },
  });
  const result: { data: { domain_id: string; intents: Intent[] }[] } =
    await responseParser(response);
  return (
    (typeof result.data !== "string" &&
      result.data?.flatMap((_) =>
        _.intents.map((intent) => {
          return { ...intent, domain_id: _.domain_id };
        })
      )) ||
    []
  );
};

export const fetchWarehouseIntentUtterances = async (id: string) => {
  const response = await interceptor(`${fetchUtterancesUrl}/${id}`);

  // const { data = [] }: { data: Utterance[] } = await responseParser(response);
  const result = await responseParser(response);
  // console.log("data",result.data[0]);
  console.log("reutrn data", {
    phraseData: result.data[0]["phraseData"],
    phraseWithEt: result.data[0]["phraseWithEt"],
  });

  // const phraseData = result.data[0]['phraseData']
  // const phraseWithEt = result.data[0]['phraseWithEt']
  // console.log("phraseData", phraseData);
  // console.log("phraseEt", phraseEt);

  return {
    phraseData: result.data[0]["phraseData"],
    phraseWithEt: result.data[0]["phraseWithEt"],
    size: result.data[0]["phraseData"].length,
  };
  // console.log("data",data[0])
  // return [data[0]['phraseData'],data[0]['phraseWithEt']];
};

export const fetchWarehouseBots = async ({
  domainId,
  searchQuery,
}: {
  domainId?: string[];
  searchQuery?: string;
}) => {
  const fetchBotsUrlModifiedUrl =
    fetchBotsUrl + (searchQuery ? `?templateName=${searchQuery}` : "");
  const response = await interceptor(fetchBotsUrlModifiedUrl, {
    method: "POST",
    body: JSON.stringify({
      domainIds: domainId || [],
    }),
    headers: { "content-type": "application/json" },
  });
  const result: { data: WarehouseBot[] } = await responseParser(response);
  return result.data || [];
};

export const fetchSkills = async ({
  domainId,
  searchQuery,
}: {
  domainId?: string[];
  searchQuery?: string;
}) => {
  const fetchSkillsUrlModifiedUrl =
    fetchSkillsUrl + (searchQuery ? `?skillName=${searchQuery}` : "");
  const response = await interceptor(fetchSkillsUrlModifiedUrl, {
    method: "POST",
    body: JSON.stringify({
      domainIds: domainId || [],
    }),
    headers: { "content-type": "application/json" },
  });
  const result: { data: Skill[] } = await responseParser(response);
  return result.data || [];
};

export const addIntentToVa = async (details: IntentDeployModel) => {
  const request = {
    token: details.token,
    action: details.action,
    // agentDisplayName: details.agentDisplayName,
    intentDisplayName: details.intentDisplayName,
    email: details.email,
    location: details.location,
    projectId: details.projectId,
  } as any;

  if (details.action === "CREATE") {
    request.agentDisplayName = details.agentDisplayName;
  } else {
    request.agentId = details.agentId;
  }
  const response = await interceptor(`${createIntentUrl}`, {
    method: "POST",
    body: JSON.stringify(request),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const uploadBotPipeline = async (details: {
  bot_name: string;
  bot_uri_es: string;
}) => {
  const response = await interceptor(
    `${uploadWarehouseBotUrl}/upload-template-bot`,
    {
      method: "POST",
      body: JSON.stringify(details),
      headers: { "content-type": "application/json" },
    }
  );
  const result: any = await responseParser(response);
  return result.message;
};

export const uploadBotCxPipeline = async (details: {
  bot_name: string;
  bot_uri_cx: string;
}) => {
  const response = await interceptor(`${uploadWarehouseBotUrl}/upload_cxBot`, {
    method: "POST",
    body: JSON.stringify(details),
    headers: { "content-type": "application/json" },
  });
  const result: any = await responseParser(response);
  return result.message;
};

export const uploadSkillPipeline = async (details: {
  skill_name: string;
  bot_uri_cx: string;
}) => {
  const response = await interceptor(
    `${uploadWarehouseBotUrl}/upload_skillAsBot`,
    {
      method: "POST",
      body: JSON.stringify(details),
      headers: { "content-type": "application/json" },
    }
  );
  const result: any = await responseParser(response);
  return result.message;
};

export const fetchSkillByBotId = async (botId: string) => {
  const response = await interceptor(`${fetchSkillsByBotIdUrl}/${botId}`, {
    method: "GET",
    headers: { "content-type": "application/json" },
  });
  const result: any = await responseParser(response);
  return result.data && result.data.length > 0 ? result.data[0] : {};
};

export const fetchIntentBySkillId = async (skillId: string, botId?: string) => {
  const req: { skillId: string; botId?: string } = {
    skillId: skillId,
  };
  if (botId) {
    req.botId = botId;
  }
  const response = await interceptor(`${fetchIntentBySkillIdUrl}`, {
    method: "POST",
    body: JSON.stringify(req),
    headers: { "content-type": "application/json" },
  });
  const result: any = await responseParser(response);
  return result.data && result.data.length > 0 ? result.data[0] : {};
};

export const virtualAgentCxPipeline = async (details: IVADeployModel) => {
  const request = {
    token: details.token,
    action: details.action,
    agentUri: details.agentUri,
    email: details.email,
    location: details.location,
    projectId: details.projectId,
    restore: details.restore,
    vaName: details.vaName,
  } as IVADeployModel;
  if (details.action === "CREATE") {
    request.displayName = details.displayName;
  } else {
    request.agentId = details.agentId;
  }
  const response = await interceptor(`${addCXAgenturl}`, {
    method: "POST",
    body: JSON.stringify(request),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const skillImport = async (details: IVADeployModel) => {
  const request = {
    token: details.token,
    action: details.action,
    flowUri: details.agentUri,
    email: details.email,
    location: details.location,
    projectId: details.projectId,
    import: details.restore,
  } as any;
  if (details.action === "CREATE") {
    request.displayName = details.displayName;
  } else {
    request.agentId = details.agentId;
  }
  const response = await interceptor(`${addSkillCxurl}`, {
    method: "POST",
    body: JSON.stringify(request),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const searchData = async (query: string, type: string) => {
  let url = `${searchApiUrl}/${query}`;
  if (type && type !== "All") {
    url += `/${type}`;
  }
  const response = await interceptor(url, {
    method: "GET",
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const addPartnerAdmin = async (details: partnerAdminModel) => {
  const response = await interceptor(`${addPartnerUrl}`, {
    method: "POST",
    body: JSON.stringify(details),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const fetchAddPartnerData = async (params: unknown) => {
  const response = await interceptor(getAddedPartnerDataUrl, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result = await responseParser(response);
  return result;
};

export const fetchAddCustomerData = async (params: unknown) => {
  const response = await interceptor(getAddedCustomerDataUrl, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result = await responseParser(response);
  return result;
};

export const fetchAddUserData = async (params: unknown) => {
  const response = await interceptor(getAddedUserDataUrl, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result = await responseParser(response);
  return result;
};

export const addPartnerUserAdmin = async (details: partnerAdminUserModel) => {
  const response = await interceptor(`${addPartnerUserUrl}`, {
    method: "POST",
    body: JSON.stringify(details),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const updatePartnerUserAdmin = async (details: any) => {
  const response = await interceptor(`${updatePartnerUserUrl}`, {
    method: "POST",
    body: JSON.stringify(details),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const deletePartnerUserAdmin = async (details: any) => {
  const response = await interceptor(`${deletePartnerUserUrl}`, {
    method: "POST",
    body: JSON.stringify(details),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const fetchApplicationLogs = async (params: unknown) => {
  const response = await interceptor(`${fetchAppQueryLogsUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const fetchAuditLogs = async (params: unknown) => {
  const response = await interceptor(`${fetchAuditQueryLogsUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const fetchExportLogs = async (params: unknown) => {
  const response = await interceptor(`${fetchExportQueryLogsUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const fetchChartUsageData = async (params: unknown) => {
  const response = await interceptor(`${fetchUsageChartDataUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

// export const fetchDashboardData = async () => {
//   const response = await interceptor(`${fetchDashboardDataUrl}`);
//   const result: any = await responseParser(response);
//   return result;
// };
export const fetchDashboardChartData = async (params: any) => {
  const response = await interceptor(`${fetchDashboardChartDetailsUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};
export const fetchTotalIntents = async (params: any) => {
  const response = await interceptor(`${fetchTotalIntentsUrl}`, {
    method: "GET",
    // body: JSON.stringify({ role: params.role }),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};
export const fetchTotalSkills = async (params: any) => {
  const response = await interceptor(`${fetchTotalSkillsOnDashboardUrl}`, {
    method: "GET",
    // body: JSON.stringify({ role: params.role }),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};
export const fetchTotalGcpProjects = async (params: any) => {
  const response = await interceptor(`${fetchTotalgcpProjectsUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};
export const fetchTotalCustomers = async (params: any) => {
  const response = await interceptor(`${fetchTotalCustomersUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};
export const fetchTotalPartners = async (params: any) => {
  const response = await interceptor(`${fetchTotalPartnersUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const getCustomersForPartnerAdminPremier = async (params: any) => {
  const response = await interceptor(
    `${getCustomersForPartnerAdminPremiumDataUrl}`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "content-type": "application/json" },
      timeout: 30 * 1000,
    }
  );
  const result: any = await responseParser(response);
  return result;
};

export const fetchEntityTypeByBotId = async (botId: string) => {
  const response = await interceptor(`${fetchEntityTypeByBotIdUrl}/${botId}`, {
    method: "GET",
    headers: { "content-type": "application/json" },
  });
  const result: any = await responseParser(response);
  return result.data && result.data.length > 0 ? result.data[0] : {};
};

export const fetchGcpProjects = async (params: unknown) => {
  const response = await interceptor(`${fetchGcpProjectsUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const addGcpProjects = async (params: unknown) => {
  const response = await interceptor(`${addGcpProjectsUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const verifyGcpProject = async (projectId: string) => {
  const response = await interceptor(`${verifyGcpProjectsUrl}`, {
    method: "POST",
    body: JSON.stringify({ projectId }),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const resendMailGcpProject = async (projectId: string) => {
  const response = await interceptor(`${resendMailGcpProjectsUrl}`, {
    method: "POST",
    body: JSON.stringify({ projectId }),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const deleteGcpProject = async (project_id: string) => {
  const response = await interceptor(`${deleteGcpProjectsUrl}`, {
    method: "POST",
    body: JSON.stringify({ project_id }),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const getGcpProjectIds = async (params: unknown) => {
  const response = await interceptor(`${fetchGcpProjectIdsUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const fetchGcpProjectsForUser = async (params: unknown) => {
  const response = await interceptor(`${fetchGcpProjectsForUserUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const responseFromNemoService = async (params: unknown) => {
  const response = await interceptor(`${receiveTextFromNemoLLMUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  if (!result.message) result.message = "Sorry, can't answer that";
  return result;
};

export const fetchUserGuideDoc = async (params: unknown) => {
  const response = await interceptor(`${fetchUserGuideUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};

export const activateDemoModeService = async () => {
  const response = await interceptor(`${activateDeoModeUrl}`, {
    method: "GET",
    headers: { "content-type": "application/json" },
    timeout: 30 * 1000,
  });
  const result: any = await responseParser(response);
  return result;
};
