import { UsersState } from "../users/users-reducer";
import {
  fetchWarehouseDomainsEffectAction,
  fetchWarehouseXmlEffectAction,
  fetchWarehouseDomainIntentsEffectAction,
  fetchWarehouseIntentUtterancesEffectAction,
  searchWarehouseIntentsEffectAction,
  fetchWarehouseDomainWarehouseBotsEffectAction,
  fetchWarehouseDomainSkillsEffectAction,
  uploadBotPipelineEffectAction,
  uploadSkillPipelineEffectAction,
  fetchPreferedDomainIntentsEffectAction,
  fetchSkillByBotIdEffectAction,
  fetchIntentBySkillIdEffectAction,
  uploadBotCxPipelineEffectAction,
  deployCXVAEffectAction,
  deploySkillCXVAEffectAction,
  searchDataEffectAction,
  createIntentAction,
  addPartnerAdminEffectAction,
  fetchAddPartnerDataEffectAction,
  fetchAddCustomerDataEffectAction,
  fetchAddUserDataEffectAction,
  fetchApplicationLogsEffectAction,
  fetchAuditLogsEffectAction,
  getCustomerForPartnerAdminPremiumEffectAction,
  fetchDashboardDataEffectAction,
  fetchEntityTypeByBotIdEffectAction,
  fetchTotalGcpProjectsAction,
  fetchExportLogsEffectAction,
  fetchUsageChartEffectAction,
  fetchGcpProjectsEffectAction,
  fetchGcpProjectIdsEffectAction,
  fetchTotalPartnersEffectAction,
  fetchTotalCustomersEffectAction,
  fetchTotalSkillsEffectAction,
  fetchTotalIntentsEffectAction,
  fetchDashboardChartDataEffectAction,
  fetchGcpProjectsForUserEffectAction,
  receiveResponseFromNemoLLMEffectAction,
  userguideUrlEffectAction,
  fetchActivateDemoModeEffectAction,
} from "./intent-warehouse-effects";
import { Domain, Intent, Utterance, PhraseData } from "./library-interfaces";

const sliceName = "intentWarehouse";

export interface DomainState {
  list: Domain[];
  loading: boolean;
}

const pageOffSet = 20;
export interface IntentState {
  list: Intent[];
  loading: boolean;
  openDialog: boolean;
  selectedIntent: Intent | null;
  paginatedList: Intent[];
  page: {
    number: number;
    start: number;
    end: number;
    endOfList: boolean;
    fetching: boolean;
  };
  openImportDialog: boolean;
}

export interface UtteranceState {
  list: PhraseData[];
  phraseData: [];
  phraseWithEt: string[];
  size: string;
  loading: boolean;
}

export interface WarehouseBot {
  bot_id: string;
  encrypted_text: string;
  displayName: string;
  longDesc: string;
  shortDesc: string;
  url: string;
  bot_name: string;
  guide: string;
  number: string;
  languages: string;
  bot_location: string;
  bot_uri_native: string | null;
  bot_uri_cx: string | null;
  bot_uri_es: string | null;
  domain_id: string;
  channel_selection: string;
  created_at?: {
    value: string;
  };
  updated_at?: string;
  enddate?: string;
}

export interface WarehouseBotsState {
  list: WarehouseBot[];
  loading: boolean;
  openDialog: boolean;
  uploadingBot: boolean;
  qChatDialog: boolean;
  vaDialog: boolean;
  selectedBot:
    | (WarehouseBot & {
        no_of_skill: number;
        no_of_intent: number;
        skill_details: Skill[];
        total_count_intents: number;
        va_welcome_msg: string;
        no_of_entity_type: number;
        msgToSpeak: string;
        userguideUrl: string;
        responseFromNemo: string;
      })
    | null;
  queryResult: WarehouseBot[];
  selectedBotLoading: boolean;
}

export interface Skill {
  skill_id: string;
  skill_name: string;
  domain_id: string;
  skill_description: string;
  bot_uri_cx: string | null;
  created_at?: {
    value: string;
  };
  updated_at?: string;
  enddate?: string;
  xml_string?: string;
}

export interface PartnerState {
  orgName: string;
  organizationType: string;
  pocEmail: string;
  licenseValidity: string;
  role?: string;
  gcpProjectId?: string;
}
export interface PartnerUserAdminState {
  firstName: string;
  lastName: string;
  // orgId: string;
  email: string;
  permissions?: any;
  role: string;
  pocEmail?: string;
  deletedUserMail?: string;
}
export interface UserState {
  id: number;
  lastName: string;
  firstName: string;
  partnerName: string;
  email: string;
}

export interface SkillsState {
  list: Skill[];
  loading: boolean;
  uploadingSkill: boolean;
  openDialog: boolean;
  selectedSkill:
    | (Skill & { intent_details: Intent[]; queryResult: Intent[] })
    | null;
  openImportDialog: boolean;
  queryResult: Skill[];
  skillLoading: boolean;
}

export interface TenantState {
  id: string;
  partnerName: string;
  type: string;
  pocEmail: string;
  licenseValidaity: string;
  createdOn: string;
}

export interface CustomerState {
  id: string;
  partnerName: string;
  customerName: string;
  type: string;
  pocEmail: string;
  licenseValidaity: string;
  createdOn: string;
}

export interface UserSate {
  id: string;
  firstName: string;
  lastName: string;
  createdOn: string;
  email: string;
  partnerName?: string;
  customerName?: string;
  permissions?: [];
}

export interface Tenant {
  data: TenantState[];
  total: number;
}
export interface Customer {
  data: CustomerState[];
  total: number;
}

export interface Users {
  data: UsersState[];
  total: number;
}

export interface GcpProject {
  data: any;
  total: number;
}

export interface GcpProjectIds {
  data: any;
  loading: boolean;
}

export interface UsageAnalytics {
  data: any;
  total: number;
  error: string;
  selectedRowCount: number;
}

export interface UserGcpProjects {
  data: any;
}

export interface DemoModeUser {
  demo_token: string;
  demo_project_id: string;
  loading: false;
}
export interface AdminUser {
  filterOpts: {
    reset: false;
    value: "";
  };
  tenant: Tenant;
  customer: Customer;
  partnerAdminPremium: Customer;
  users: Users;
  organization: {
    id: string;
    name: string;
    organizationType: string;
    pocEmail: string;
    addedBy: string;
  };
  loading: boolean;
  appLogs: UsageAnalytics;
  auditLogs: UsageAnalytics;
  exportLogs: UsageAnalytics;
  dashboardData: any;
  gcpProjects: GcpProject;
  gcpProjectIds: GcpProjectIds;
  usageChart: any;
  userGcpProjects: UserGcpProjects;
}

export interface SelectedPartnerRow {
  licenseValidity: string;
  name: string;
  organizationType: string;
  pocEmail: string;
  timestamp: string;
}

export interface WarehouseDataState {
  warehosue_user: UserState;
  admin_user: AdminUser;
  demo_mode_user: DemoModeUser;
  warehouse_partner: PartnerState;
  warehouse_domains: DomainState;
  warehouse_domain_intents: IntentState;
  warehouse_domain_skills: SkillsState;
  warehouse_domain_bots: WarehouseBotsState;
  warehouse_intent_utterances: UtteranceState;
  selectedDomains?: Domain[];
  selectedIntent?: Intent;
  intentQueryResult?: Intent[];
  loading: boolean;
  add_to_intent_loader: boolean;
  warehouse_preferred_industry_intents: IntentState;
  openAddIntentToVa: boolean;
  openFlowChart: boolean;
  openAddPartner: boolean;
  openAddUser: boolean;
  openAddUserValue: string;
  openDeletePartnerDialog: boolean;
  openDeleteUserDialog: boolean;
  openEditPartnerModal: boolean;
  deletedUserMail: string;
  openEditUserModal: boolean;
  selectedUser: PartnerUserAdminState;
  selectedPartner: SelectedPartnerRow;
  query: string;
  selectedDomain: {
    domain_name: string;
    domain_id: string;
  };
}

export const initialState: WarehouseDataState = {
  demo_mode_user: {
    demo_token: "",
    demo_project_id: "",
    loading: false,
  },
  openAddPartner: false,
  selectedPartner: {
    licenseValidity: "",
    name: "",
    organizationType: "",
    pocEmail: "",
    timestamp: "",
  },
  openAddUser: false,
  openAddUserValue: "",
  openAddIntentToVa: false,
  openDeletePartnerDialog: false,
  openEditPartnerModal: false,
  openDeleteUserDialog: false,
  openEditUserModal: false,
  openFlowChart: false,
  deletedUserMail: "",
  selectedUser: {
    firstName: "",
    lastName: "",
    // orgId: "",
    email: "",
    permissions: [],
    role: "",
    deletedUserMail: "",
  },
  warehouse_domains: {
    list: [],
    loading: false,
  },
  warehouse_domain_intents: {
    list: [],
    loading: false,
    openDialog: false,
    selectedIntent: null,
    paginatedList: [],
    page: {
      number: 0,
      start: 0,
      end: pageOffSet,
      endOfList: false,
      fetching: false,
    },
    openImportDialog: false,
  },
  warehouse_domain_bots: {
    list: [],
    loading: false,
    openDialog: false,
    qChatDialog: false,
    selectedBot: null,
    uploadingBot: false,
    vaDialog: false,
    queryResult: [],
    selectedBotLoading: false,
  },
  warehouse_intent_utterances: {
    list: [],
    phraseData: [],
    phraseWithEt: [],
    size: "",
    loading: false,
  },
  warehouse_domain_skills: {
    list: [],
    loading: false,
    uploadingSkill: false,
    openDialog: false,
    selectedSkill: null,
    openImportDialog: false,
    queryResult: [],
    skillLoading: false,
  },
  selectedDomains: [],
  loading: false,
  intentQueryResult: [],
  add_to_intent_loader: false,
  warehouse_preferred_industry_intents: {
    list: [],
    loading: false,
    openDialog: false,
    selectedIntent: null,
    paginatedList: [],
    page: {
      number: 0,
      start: 0,
      end: pageOffSet,
      endOfList: false,
      fetching: false,
    },
    openImportDialog: false,
  },
  query: "",
  selectedDomain: {
    domain_name: "",
    domain_id: "",
  },
  warehouse_partner: {
    orgName: "",
    organizationType: "",
    licenseValidity: "",
    pocEmail: "",
    // role: "",
  },
  warehosue_user: {
    id: 0,
    lastName: "",
    firstName: "",
    email: "",
    partnerName: " ",
  },
  admin_user: {
    filterOpts: {
      reset: false,
      value: "",
    },
    tenant: { data: [], total: 0 },
    customer: { data: [], total: 0 },
    partnerAdminPremium: { data: [], total: 0 },
    users: { data: [], total: 0 },
    organization: {
      id: "",
      name: "",
      organizationType: "",
      pocEmail: "",
      addedBy: "",
    },
    loading: false,
    appLogs: { data: [], total: 0, error: "", selectedRowCount: 10 },
    auditLogs: { data: [], total: 0, error: "", selectedRowCount: 10 },
    exportLogs: { data: [], total: 0, error: "", selectedRowCount: 10 },
    dashboardData: {
      partners: { count: 0, loading: false },
      customers: { count: 0, loading: false },
      projects: { count: 0, loading: false },
      intents: { count: 0, loading: false },
      skills: { count: 0, loading: false },
      charts: {
        users: 0,
        partners: 0,
        customers: 0,
        loading: false,
      },
    },
    gcpProjects: { data: [], total: 0 },
    gcpProjectIds: { data: [], loading: false },
    usageChart: {},
    userGcpProjects: { data: [] },
  },
};

function reducer(
  state: WarehouseDataState,
  action: { type: string; payload?: any }
) {
  // console.log("inside  IW reducer", action);

  switch (action.type) {
    case "intent-warehouse/filterTenants":
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          tenant: action.payload,
        },
      };
    case `${fetchWarehouseDomainsEffectAction}-pending`:
      return {
        ...state,
        warehouse_domains: {
          ...state.warehouse_domains,
          loading: true,
        },
      };
    case `${fetchWarehouseDomainsEffectAction}-completed`:
      action.payload.sort((a: any, b: any) =>
        a.domain_name.localeCompare(b.domain_name)
      );
      return {
        ...state,
        warehouse_domains: {
          ...state.warehouse_domains,
          loading: false,
          list: action.payload.map((_: any) => {
            return {
              is_skill_view_all: false,
              sk_length: 18,
              is_intent_view_all: false,
              intent_length: 18,
              ..._,
            };
          }),
        },
      };
    case `${fetchWarehouseDomainsEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domains: {
          ...state.warehouse_domains,
          loading: false,
        },
      };
    case `${fetchWarehouseDomainIntentsEffectAction}-pending`:
      return {
        ...state,
        warehouse_domain_intents: {
          ...state.warehouse_domain_intents,
          loading: true,
        },
      };
    case `${fetchWarehouseDomainIntentsEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_intents: {
          ...state.warehouse_domain_intents,
          loading: false,
          list: action.payload,
        },
      };
    case `${fetchWarehouseDomainIntentsEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domain_intents: {
          ...state.warehouse_domain_intents,
          loading: false,
        },
      };
    case `${fetchWarehouseDomainWarehouseBotsEffectAction}-pending`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          loading: true,
        },
      };
    case `${fetchWarehouseDomainWarehouseBotsEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          loading: false,
          list: action.payload.filter((_: any) => _.domain_id !== ""),
        },
      };
    case `${fetchWarehouseDomainWarehouseBotsEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          loading: false,
        },
      };

    case `${fetchWarehouseDomainSkillsEffectAction}-pending`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          loading: true,
        },
      };
    case `${fetchWarehouseDomainSkillsEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          loading: false,
          list: action.payload,
        },
      };
    case `${fetchWarehouseDomainSkillsEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          loading: false,
        },
      };

    case `${fetchWarehouseIntentUtterancesEffectAction}-pending`:
      return {
        ...state,
        warehouse_intent_utterances: {
          ...state.warehouse_intent_utterances,
          loading: true,
        },
      };
    case `${fetchWarehouseIntentUtterancesEffectAction}-completed`:
      console.log("action.payload", action.payload);
      return {
        ...state,
        warehouse_intent_utterances: {
          ...state.warehouse_intent_utterances,
          loading: false,
          list: action.payload,
          phraseData: action.payload.phraseData,
          phraseWithEt: action.payload.phraseWithEt,
          size: action.payload.size,
        },
      };
    case `${fetchWarehouseIntentUtterancesEffectAction}-rejected`:
      return {
        ...state,
        warehouse_intent_utterances: {
          ...state.warehouse_intent_utterances,
          loading: false,
        },
      };
    case `${searchWarehouseIntentsEffectAction}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${searchWarehouseIntentsEffectAction}-rejected`:
      return {
        ...state,
        loading: false,
      };
    case `${searchWarehouseIntentsEffectAction}-completed`:
      return {
        ...state,
        loading: false,
        intentQueryResult: action.payload,
      };
    case `${uploadBotPipelineEffectAction}-pending`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          uploadingBot: true,
        },
      };
    case `${uploadBotPipelineEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          uploadingBot: false,
        },
      };
    case `${uploadBotPipelineEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          uploadingBot: false,
        },
      };
    case `${uploadSkillPipelineEffectAction}-pending`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          uploadingSkill: true,
        },
      };
    case `${uploadSkillPipelineEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          uploadingSkill: false,
        },
      };
    case `${uploadSkillPipelineEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          uploadingSkill: false,
        },
      };
    case `${uploadBotCxPipelineEffectAction}-pending`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          uploadingSkill: true,
        },
      };
    case `${uploadBotCxPipelineEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          uploadingSkill: false,
        },
      };
    case `${uploadBotCxPipelineEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          uploadingSkill: false,
        },
      };
    case `intent-warehouse/setSelectedIntent`:
      return {
        ...state,
        selectedIntent: action.payload,
      };
    case "intent-warehouse/openBotDialog":
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          vaDialog: true,
        },
      };
    case "intent-warehouse/openQChatDialog":
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          qChatDialog: action.payload,
        },
      };
    case "intent-warehouse/closeBotDialog":
      return {
        ...state,
        demo_mode_user: {
          demo_token: "",
          demo_project_id: "",
        },
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          vaDialog: false,
        },
      };
    case "intent-warehouse/openSkillDialog":
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          openDialog: true,
          selectedSkill: {
            ...action.payload,
            intent_details: [],
            queryResult: [],
          },
        },
      };
    case "intent-warehouse/closeSkillDialog":
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          openDialog: false,
          selectedSkill: null,
        },
      };
    case "intent-warehouse/openSkillImportDialog":
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          openImportDialog: true,
        },
      };
    case "intent-warehouse/closeSkillImportDialog":
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          openImportDialog: false,
        },
      };
    case "intent-warehouse/openIntentDialog":
      return {
        ...state,
        warehouse_domain_intents: {
          ...state.warehouse_domain_intents,
          openDialog: true,
          selectedIntent: action.payload,
        },
      };
    case "intent-warehouse/closeIntentDialog":
      return {
        ...state,
        warehouse_domain_intents: {
          ...state.warehouse_domain_intents,
          openDialog: false,
          selectedIntent: null,
        },
      };
    case "intent-warehouse/searchBots":
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          queryResult: state.warehouse_domain_bots.list.filter((_) =>
            ("" + _.displayName)
              .toLowerCase()
              .includes(action.payload.toLowerCase())
          ),
        },
      };
    case "intent-warehouse/searchSkills":
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          queryResult: state.warehouse_domain_skills.list.filter((_) =>
            _.skill_name.toLowerCase().includes(action.payload.toLowerCase())
          ),
        },
      };
    case "intent-warehouse/searchIntent":
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          selectedSkill: {
            ...state.warehouse_domain_skills.selectedSkill,
            queryResult:
              state.warehouse_domain_skills.selectedSkill?.intent_details.filter(
                (_) =>
                  _.intent_name
                    .toLowerCase()
                    .includes(action.payload.toLowerCase())
              ),
          },
        },
      };
    case "intent-warehouse/openCloseUtteranceDialog":
      return {
        ...state,
        openAddIntentToVa: action.payload,
      };

    case "intent-warehouse/openCloseflowChartDialog":
      return {
        ...state,
        openFlowChart: action.payload,
      };

    case `${searchDataEffectAction}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${searchDataEffectAction}-completed`:
      return {
        ...state,
        loading: false,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          queryResult: action.payload.data.skills || [],
        },
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          queryResult: action.payload.data.bot_templates || [],
        },
        intentQueryResult: action.payload.data.intents || [],
      };
    case `${searchDataEffectAction}-rejected`:
      return {
        ...state,
        loading: false,
      };
    case `${fetchPreferedDomainIntentsEffectAction}-pending`:
      return {
        ...state,
        warehouse_preferred_industry_intents: {
          ...state.warehouse_preferred_industry_intents,
          loading: true,
        },
      };
    case `${fetchPreferedDomainIntentsEffectAction}-completed`:
      return {
        ...state,
        warehouse_preferred_industry_intents: {
          ...state.warehouse_preferred_industry_intents,
          loading: false,
          list: action.payload,
        },
      };
    case `${fetchPreferedDomainIntentsEffectAction}-rejected`:
      return {
        ...state,
        warehouse_preferred_industry_intents: {
          ...state.warehouse_preferred_industry_intents,
          loading: false,
        },
      };
    case "intent-warehouse/clearDomainIntents":
      return {
        ...state,
        warehouse_intent_utterances: {
          ...state.warehouse_intent_utterances,
          list: [],
        },
        selectedDomain: {
          domain_name: "",
          domain_id: "",
        },
      };
    case "intent-warehouse/viewAllSkills":
      return {
        ...state,
        warehouse_domains: {
          ...state.warehouse_domains,
          loading: false,
          list: state.warehouse_domains.list.map((_: any) => {
            if (_.domain_id === action.payload.domain_id) {
              _.is_skill_view_all = action.payload.isViewAll;
              _.sk_length = action.payload.isViewAll
                ? state.warehouse_domain_skills.list.length
                : 18;
            }
            return _;
          }),
        },
      };
    case "intent-warehouse/viewAllIntents":
      return {
        ...state,
        warehouse_domains: {
          ...state.warehouse_domains,
          loading: false,
          list: state.warehouse_domains.list.map((_: any) => {
            if (_.domain_id === action.payload.domain_id) {
              _.is_intent_view_all = action.payload.isViewAll;
              _.intent_length = action.payload.isViewAll
                ? state.warehouse_domain_intents.list.length
                : 18;
            }
            return _;
          }),
        },
      };
    case "intent-warehouse/intentAndSkillSort":
      let sortList: any[] = [];
      let sortVa: any[] = [];
      let chip =
        action.payload.domain_id === "domain" ? "domains" : action.payload.chip;
      console.log("chip ", chip, action.payload.domain_id);

      if (action.payload.sort === "asc") {
        if (chip === "Intents") {
          sortList = state.warehouse_domain_intents.list.filter(
            (_) => _.domain_id === action.payload.domain_id
          );
          sortList = sortList.sort((a, b) =>
            a.intent_name.localeCompare(b.intent_name)
          );
        }
        if (chip === "Skills") {
          sortVa = state.warehouse_domain_bots.list.filter(
            (_) => _.domain_id === action.payload.domain_id
          );
          sortVa = sortVa.sort((a, b) =>
            a.displayName.localeCompare(b.displayName)
          );
          sortList = state.warehouse_domain_skills.list.filter(
            (_) => _.domain_id === action.payload.domain_id
          );
          sortList = sortList.sort((a, b) =>
            a.skill_name.localeCompare(b.skill_name)
          );
        }
        if (chip === "domains") {
          sortList = state.warehouse_domains.list.sort((a, b) =>
            a.domain_name.localeCompare(b.domain_name)
          );
        }
        if (chip === "VAs") {
          sortVa = state.warehouse_domain_bots.list.filter(
            (_) => _.domain_id === action.payload.domain_id
          );

          sortVa = sortVa.sort((a, b) =>
            a.displayName.localeCompare(b.displayName)
          );
        }
      }
      if (action.payload.sort === "desc") {
        if (chip === "Intents") {
          sortList = state.warehouse_domain_intents.list.filter(
            (_) => _.domain_id === action.payload.domain_id
          );
          sortList = sortList.sort((a, b) =>
            b.intent_name.localeCompare(a.intent_name)
          );
        }
        if (chip === "Skills") {
          sortVa = state.warehouse_domain_bots.list.filter(
            (_) => _.domain_id === action.payload.domain_id
          );
          sortVa = sortVa.sort((a, b) =>
            b.displayName.localeCompare(a.displayName)
          );
          sortList = state.warehouse_domain_skills.list.filter(
            (_) => _.domain_id === action.payload.domain_id
          );
          sortList = sortList.sort((a, b) =>
            b.skill_name.localeCompare(a.skill_name)
          );
        }
        if (chip === "domains") {
          sortList = state.warehouse_domains.list.sort((a, b) =>
            b.domain_name.localeCompare(a.domain_name)
          );
        }

        if (chip === "VAs") {
          sortVa = state.warehouse_domain_bots.list.filter(
            (_) => _.domain_id === action.payload.domain_id
          );
          sortVa = sortVa.sort((a, b) =>
            b.displayName.localeCompare(a.displayName)
          );
        }
      }

      return {
        ...state,
        warehouse_domain_intents: {
          ...state.warehouse_domain_intents,
          list:
            chip === "Intents"
              ? state.warehouse_domain_intents.list
                  .filter((_) => _.domain_id !== action.payload.domain_id)
                  .concat(sortList)
              : state.warehouse_domain_intents.list,
        },
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          list:
            chip === "Skills"
              ? state.warehouse_domain_skills.list
                  .filter((_) => _.domain_id !== action.payload.domain_id)
                  .concat(sortList)
              : state.warehouse_domain_skills.list,
        },
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          list:
            chip === "Skills"
              ? state.warehouse_domain_bots.list
                  .filter((_) => _.domain_id !== action.payload.domain_id)
                  .concat(sortVa)
              : chip === "VAs"
              ? state.warehouse_domain_bots.list
                  .filter((_) => _.domain_id !== action.payload.domain_id)
                  .concat(sortVa)
              : state.warehouse_domain_bots.list,
        },
        warehouse_domains: {
          ...state.warehouse_domains,
          list: chip === "domains" ? sortList : state.warehouse_domains.list,
        },
      };
    case "app/logOut": {
      return {
        ...initialState,
      };
    }
    case `${fetchSkillByBotIdEffectAction}-pending`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          selectedBotLoading: true,
        },
      };
    case `${fetchSkillByBotIdEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          selectedBot: {
            ...state.warehouse_domain_bots.selectedBot,
            ...action.payload,
          },
          selectedBotLoading: false,
        },
      };
    case `${fetchSkillByBotIdEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          selectedBotLoading: false,
        },
      };
    case `${fetchIntentBySkillIdEffectAction}-pending`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          skillLoading: true,
        },
      };
    case `${fetchIntentBySkillIdEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          selectedSkill: {
            ...state.warehouse_domain_skills.selectedSkill,
            ...action.payload,
          },
          skillLoading: false,
        },
      };
    case `${fetchIntentBySkillIdEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          skillLoading: false,
        },
      };
    case `${fetchWarehouseXmlEffectAction}-pending`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          skillLoading: true,
        },
      };
    case `${fetchWarehouseXmlEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          selectedSkill: {
            ...state.warehouse_domain_skills.selectedSkill,

            ...action.payload,
          },
          skillLoading: false,
        },
      };
    case `${fetchWarehouseXmlEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          skillLoading: false,
        },
      };
    case "intent-warehouse/updateSelectedDomainName":
      return {
        ...state,
        selectedDomain: {
          ...state.selectedDomain,
          ...action.payload,
        },
      };
    case "intent-warehouse/reset-intent-warehouse-intents":
      return {
        ...state,
        warehouse_domain_intents: {
          ...state.warehouse_domain_intents,
          ...initialState.warehouse_domain_intents,
        },
      };
    case "intent-warehouse/selected-bot":
      const sb = state.warehouse_domain_bots.list.find(
        (_) => _.bot_id === action.payload
      );
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          selectedBot: {
            ...sb,
            no_of_entity_type: 0,
            no_of_skill: 0,
            no_of_intent: 0,
            total_count_intents: 0,
            skill_details: [],
          },
        },
      };
    case "intent-warehouse/selected-skill":
      const ss = state.warehouse_domain_skills.list.find(
        (_) => _.skill_id === action.payload
      );
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          selectedSkill: {
            ...ss,
            intent_details: [],
          },
        },
      };
    case "intent-warehouse/selected-intent":
      const si = state.warehouse_domain_intents.list.find(
        (_) => _.intent_id === action.payload
      );
      return {
        ...state,
        warehouse_domain_intents: {
          ...state.warehouse_domain_intents,
          selectedIntent: {
            ...si,
          },
        },
      };
    case "intent-warehouse/reset-selected-bot":
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          selectedBot: action.payload,
        },
      };
    case "intent-warehouse/reset-selected-skill":
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          selectedSkill: action.payload,
        },
      };
    case "intent-warehouse/reset-selected-intent":
      return {
        ...state,
        warehouse_domain_intents: {
          ...state.warehouse_domain_intents,
          selectedIntent: action.payload,
        },
      };
    case `${deployCXVAEffectAction}-pending`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          uploadingBot: true,
        },
      };
    case `${deployCXVAEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          uploadingBot: false,
        },
      };
    case `${deployCXVAEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          uploadingBot: false,
        },
      };
    case `${deploySkillCXVAEffectAction}-pending`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          uploadingSkill: true,
        },
      };
    case `${deploySkillCXVAEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          uploadingSkill: false,
        },
      };
    case `${deploySkillCXVAEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domain_skills: {
          ...state.warehouse_domain_skills,
          uploadingSkill: false,
        },
      };

    case `${createIntentAction}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${createIntentAction}-completed`:
      return {
        ...state,
        loading: false,
      };
    case `${createIntentAction}-rejected`:
      return {
        ...state,
        loading: false,
      };
    case `${fetchEntityTypeByBotIdEffectAction}-pending`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          selectedBotLoading: true,
        },
      };

    //Super Admin

    case `${addPartnerAdminEffectAction}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${addPartnerAdminEffectAction}-completed`:
      return {
        ...state,
        loading: false,
      };
    case `${addPartnerAdminEffectAction}-rejected`:
      return {
        ...state,
        loading: false,
      };

    case `${fetchAddPartnerDataEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          filterOpts: {
            ...state.admin_user.filterOpts,
          },
          loading: true,
        },
      };
    case `${fetchAddPartnerDataEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          filterOpts: {
            ...state.admin_user.filterOpts,
          },
          tenant: { data: action.payload.data, total: action.payload.total },
          loading: false,
        },
      };
    case `${fetchAddPartnerDataEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
        },
      };

    case `${fetchAddCustomerDataEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: true,
        },
      };
    case `${fetchAddCustomerDataEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          customer: { data: action.payload.data, total: action.payload.total },
          loading: false,
        },
      };
    case `${fetchAddCustomerDataEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
        },
      };

    case `${fetchAddCustomerDataEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: true,
        },
      };
    case `${fetchAddCustomerDataEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          customer: { data: action.payload.data, total: action.payload.total },
          loading: false,
        },
      };
    case `${fetchAddCustomerDataEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
        },
      };

    case `${getCustomerForPartnerAdminPremiumEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: true,
        },
      };
    case `${getCustomerForPartnerAdminPremiumEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          partnerAdminPremium: action.payload,
          loading: false,
        },
      };
    case `${getCustomerForPartnerAdminPremiumEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
        },
      };

    case `${fetchAddUserDataEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: true,
        },
      };
    case `${fetchAddUserDataEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          users: { data: action.payload.data, total: action.payload.total },
          loading: false,
        },
      };
    case `${fetchAddUserDataEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
        },
      };
    case `${fetchApplicationLogsEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: true,
        },
      };
    case `${fetchApplicationLogsEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
          appLogs: {
            ...state.admin_user.appLogs,
            data: action.payload.data,
            total: action.payload.total,
            error: action.payload.error,
          },
        },
      };
    case `${fetchApplicationLogsEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
        },
      };

    case `${fetchGcpProjectsForUserEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: true,
        },
      };
    case `${fetchGcpProjectsForUserEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
          userGcpProjects: {
            data: action.payload?.data || [],
          },
        },
      };
    case `${fetchGcpProjectsForUserEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
        },
      };

    case `${fetchAuditLogsEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: true,
        },
      };
    case `${fetchAuditLogsEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
          auditLogs: {
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };
    case `${fetchAuditLogsEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
        },
      };
    case `${fetchExportLogsEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: true,
        },
      };
    case `${fetchExportLogsEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
          exportLogs: {
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };
    case `${fetchExportLogsEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
        },
      };

    case `${fetchUsageChartEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          usageChart: {
            loading: true,
          },
        },
      };
    case `${fetchUsageChartEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
          usageChart: { loading: false, data: action.payload.data },
        },
      };
    case `${fetchUsageChartEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          usageChart: {
            loading: false,
          },
        },
      };

    // case `${receiveResponseFromNemoLLMEffectAction}-pending`:
    //   return {
    //     ...state,
    //     warehouse_domain_bots: {
    //       ...state.warehouse_domain_bots,
    //       selectedBot: {
    //         ...state.warehouse_domain_bots.selectedBot,
    //         responseFromNemo: "Loading...",
    //       },
    //     },
    //   };
    case `${receiveResponseFromNemoLLMEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          selectedBot: {
            ...state.warehouse_domain_bots.selectedBot,
            responseFromNemo: action.payload?.message || "",
          },
        },
      };
    // case `${receiveResponseFromNemoLLMEffectAction}-rejected`:
    //   return {
    //     ...state,
    //     warehouse_domain_bots: {
    //       ...state.warehouse_domain_bots,
    //       selectedBot: {
    //         ...state.warehouse_domain_bots.selectedBot,
    //         responseFromNemo: action.payload?.message || "",
    //       },
    //     },
    //   };

    case `${fetchGcpProjectsEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: true,
        },
      };
    case `${fetchGcpProjectsEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
          gcpProjects: {
            data: action.payload.data,
            total: action.payload?.total || 0,
          },
        },
      };
    case `${fetchGcpProjectsEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
        },
      };

    case `${fetchGcpProjectIdsEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          gcpProjectIds: {
            ...state.admin_user.gcpProjectIds,
            loading: true,
          },
        },
      };
    case `${fetchGcpProjectIdsEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          gcpProjectIds: {
            data: action.payload?.data || [],
            loading: false,
          },
        },
      };
    case `${fetchGcpProjectIdsEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          gcpProjectIds: {
            ...state.admin_user.gcpProjectIds,
            loading: false,
          },
        },
      };

    case `intent-warehouse/msgToSpeak-completed`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          selectedBot: {
            ...state.warehouse_domain_bots.selectedBot,
            msgToSpeak: action.payload || "",
          },
        },
      };
    case `${fetchEntityTypeByBotIdEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          selectedBot: {
            ...state.warehouse_domain_bots.selectedBot,
            ...action.payload,
          },
          selectedBotLoading: false,
        },
      };
    case `${fetchEntityTypeByBotIdEffectAction}-rejected`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          selectedBotLoading: false,
        },
      };

    case `${userguideUrlEffectAction}-pending`:
    case `${userguideUrlEffectAction}-rejected`:
    case `${userguideUrlEffectAction}-completed`:
      return {
        ...state,
        warehouse_domain_bots: {
          ...state.warehouse_domain_bots,
          selectedBot: {
            ...state.warehouse_domain_bots.selectedBot,
            userguideUrl: action.payload?.doc || "",
          },
        },
      };

    case `${fetchTotalPartnersEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            partners: {
              ...state.admin_user.dashboardData.parnters,
              loading: true,
            },
          },
        },
      };
    case `${fetchTotalPartnersEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            partners: {
              count: action.payload?.data?.data,
              loading: false,
            },
          },
        },
      };
    case `${fetchTotalPartnersEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            partners: {
              ...state.admin_user.dashboardData.parnters,
              loading: false,
            },
          },
        },
      };

    case `${fetchTotalCustomersEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            customers: {
              ...state.admin_user.dashboardData.customers,
              loading: true,
            },
          },
        },
      };
    case `${fetchTotalCustomersEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            customers: {
              count: action.payload?.data?.data,
              loading: false,
            },
          },
        },
      };
    case `${fetchTotalCustomersEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            customers: {
              ...state.admin_user.dashboardData.customers,
              loading: false,
            },
          },
        },
      };

    case `${fetchTotalGcpProjectsAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            projects: {
              ...state.admin_user.dashboardData.projects,
              loading: true,
            },
          },
        },
      };
    case `${fetchTotalGcpProjectsAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            projects: {
              count: [
                action.payload?.data?.unverified || 0,
                action.payload?.data?.verified || 0,
              ],
              loading: false,
            },
          },
        },
      };
    case `${fetchTotalGcpProjectsAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            projects: {
              ...state.admin_user.dashboardData.projects,
              loading: false,
            },
          },
        },
      };

    case `${fetchTotalSkillsEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            skills: {
              ...state.admin_user.dashboardData.skills,
              loading: true,
            },
          },
        },
      };
    case `${fetchTotalSkillsEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            skills: {
              count: action.payload?.data?.data,
              loading: false,
            },
          },
        },
      };
    case `${fetchTotalSkillsEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            skills: {
              ...state.admin_user.dashboardData.skills,
              loading: false,
            },
          },
        },
      };

    case `${fetchTotalIntentsEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            intents: {
              ...state.admin_user.dashboardData.intents,
              loading: true,
            },
          },
        },
      };
    case `${fetchTotalIntentsEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            intents: {
              count: action.payload?.data?.data,
              loading: false,
            },
          },
        },
      };
    case `${fetchTotalIntentsEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            intents: {
              ...state.admin_user.dashboardData.intents,
              loading: false,
            },
          },
        },
      };

    case `${fetchDashboardChartDataEffectAction}-pending`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            charts: {
              ...state.admin_user.dashboardData.charts,
              loading: true,
            },
          },
        },
      };
    case `${fetchDashboardChartDataEffectAction}-completed`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            charts: {
              users: action.payload?.data?.data?.chart_quan_users_count,
              partners: action.payload?.data?.data?.chart_partners_count,
              customers: action.payload?.data?.data?.chart_customers_count,
              loading: false,
            },
          },
        },
      };
    case `${fetchDashboardChartDataEffectAction}-rejected`:
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          dashboardData: {
            ...state.admin_user.dashboardData,
            charts: {
              ...state.admin_user.dashboardData.charts,
              loading: false,
            },
          },
        },
      };

    case `${fetchActivateDemoModeEffectAction}-pending`:
    case `${fetchActivateDemoModeEffectAction}-rejected`:
      return {
        ...state,
        demo_mode_user: {
          loading: true,
        },
      };
    case `${fetchActivateDemoModeEffectAction}-completed`:
      return {
        ...state,
        demo_mode_user: {
          loading: false,
          demo_token: action.payload?.demo_token || "",
          demo_project_id: action.payload?.demo_project_id || "",
        },
      };

    // case `${fetchDashboardDataEffectAction}-pending`:
    //   return {
    //     ...state,
    //     admin_user: {
    //       ...state.admin_user,
    //       dashboardData: {
    //         loading: true,
    //       },
    //     },
    //   };
    // case `${fetchDashboardDataEffectAction}-completed`:
    //   return {
    //     ...state,
    //     admin_user: {
    //       ...state.admin_user,
    //       dashboardData: {
    //         total_gcp_projects: action.payload[0].data.data,
    //         total_customers: action.payload[1].data.data,
    //         total_partners: action.payload[2].data.data,
    //         total_skill: action.payload[3].data.data,
    //         total_intent: action.payload[4].data.data,
    //         chart_customers_count:
    //           action.payload[5].data.data.chart_customers_count,
    //         chart_partners_count:
    //           action.payload[5].data.data.chart_partners_count,
    //         chart_quan_users_count:
    //           action.payload[5].data.data.chart_quan_users_count,
    //       },
    //     },
    //   };
    // case `${fetchDashboardDataEffectAction}-rejected`:
    //   return {
    //     ...state,
    //     admin_user: {
    //       ...state.admin_user,
    //       dashboardData: {
    //         loading: true,
    //       },
    //     },
    //   };
    case "super-admin/usage-analytics/rowCountChanged":
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          loading: false,
          appLogs: {
            ...state.admin_user.appLogs,
            selectedRowCount: action.payload,
          },
        },
      };
    case "super-admin/manage-tenant/openCreatePartnerDialog":
      return {
        ...state,
        openAddPartner: true,
      };
    case "super-admin/manage-tenant/openEditPartnerDialog":
      return {
        ...state,
        openEditPartnerModal: true,
        selectedPartner: action.payload,
      };
    case "super-admin/manage-tenant/closeCreatePartnerDialog":
      return {
        ...state,
        openAddPartner: false,
        openEditPartnerModal: false,
      };
    case "super-admin/manage-tenant/openDeletePartnerConfirmationDialog":
      return {
        ...state,
        openDeletePartnerDialog: true,
        selectedPartner: action.payload,
      };
    case "super-admin/manage-tenant/closeDeletePartnerConfirmationDialog":
      return {
        ...state,
        openDeletePartnerDialog: false,
      };
    case "super-admin/manage-tenant/openCreateUserDialog":
      return {
        ...state,
        openAddUser: true,
        openAddUserValue: action.payload.role,
      };
    case "super-admin/manage-tenant/openEditUserDialog":
      return {
        ...state,
        openEditUserModal: true,
        selectedUser: action.payload,
      };
    case "super-admin/manage-tenant/closeCreateUserDialog":
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          permissions: [],
        },
        openAddUser: false,
        OpenAddUserValue: "",
        openEditUserModal: false,
      };
    case "super-admin/manage-tenant/openDeleteUserConfirmationDialog":
      return {
        ...state,
        openDeleteUserDialog: true,
        selectedUser: {
          ...action.payload,
          permissions: [],
          deletedUserMail: action.payload.id,
        },
      };
    case "super-admin/manage-tenant/closeDeleteUserConfirmationDialog":
      return {
        ...state,
        openDeleteUserDialog: false,
      };
    case "super-admin/manage-tenant/resetFilterOnOrgs":
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          filterOpts: {
            ...state.admin_user.filterOpts,
            reset: true,
            value: "",
          },
        },
      };
    case "super-admin/manage-tenant/setFilterType":
      return {
        ...state,
        admin_user: {
          ...state.admin_user,
          filterOpts: {
            ...state.admin_user.filterOpts,
            reset: false,
            value: action.payload,
          },
        },
      };
    default:
      return {
        ...state,
      };
  }
}

const intentWarehouseSlice = {
  initialState,
  sliceName,
  reducer,
};

export default intentWarehouseSlice;
