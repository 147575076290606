import { Info } from "@mui/icons-material";
import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { Redirect } from "react-router-dom";
import { centralLogger } from "./services/api/logging-service";

interface IErrorBoundary {
  hasError: boolean;
}

const url = process.env.REACT_APP_BASE_URL;

export default class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    if (!sessionStorage["ags_token"]) {
      return <Redirect to="/" />;
    }
    // Display fallback UI
    this.setState({ hasError: true });
    const errorPyalod = {
      path: window.location?.href,
      message: error.message,
      errorStackTrace: JSON.stringify(info),
      level: "error",
      type: "UI",
      timestamp: new Date(),
    };
    centralLogger(errorPyalod);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Stack pt={8}>
          <Info
            sx={{
              fontSize: 40,
              textAlign: "center",
              mx: "auto",
              color: "error.main",
            }}
          />
          <Typography variant="h5" textAlign="center" mt={1}>
            Something went wrong. We have Notified Our Development Team.
            Apologies for the inconvenience.
            <Link
              color="primary"
              onClick={() => window.open(`${url}/dashboard`, "_self")}
            >
              Click Here to Reload Dashboard
            </Link>
          </Typography>
        </Stack>
      );
    }
    return this.props.children;
  }
}
