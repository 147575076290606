export const enum AppURLs {
  OverviewUrl = "/dashboard/overview",
  SelectAgentURL = "/dashboard/select-agent",
  UsersURL = "/dashboard/users",
  DefaultURL = "/dashboard",
  ProfileURL = "/dashboard/profile",
  WelcomeURL = "/onboarding/welcome",
  PrivacyPolicyURL = "/onboarding/privacy-policy",
}
export const enum AgentAssistURLs {
  ConversationProfilesURL = "/dashboard/agent-assist/conversation-profiles",
  EditConversationProfilesURL = "/dashboard/agent-assist/edit-conversation-profile",
  NewConversationProfileURL = "/dashboard/agent-assist/conversation-profiles/new",
  ModelsUrl = "/dashboard/agent-assist/conversation-models",
  NewModelsUrl = "/dashboard/agent-assist/conversation-models/new",
  ConversationDataURL = "/dashboard/agent-assist/conversation-data",
  NewConversationDatasetURL = "/dashboard/agent-assist/new-conversation-dataset",
  NewKnowledgeBaseURL = "/dashboard/agent-assist/new-conversation-knowledge-base",
  ConversationFAQsURL = "/dashboard/agent-assist/conversation-data/conversation-faq",
  DefaultURL = "/dashboard/agent-assist",
  KnowledgeBaseDocumentsListURL = "/dashboard/agent-assist/knowledge-base-documents-list",
}

export const enum SuperAdmin {
  DashboardURL = "/dashboard/admin/admin-dashboard",
  TenantUrl = "/dashboard/admin/tenant",
  ManageCustomersUrl = "/dashboard/admin/manage-customers",
  ManageUsersUrl = "/dashboard/admin/manage-users",
  UsuageAnalyticsUrl = "/dashboard/admin/usage-analytics",
  GcpProjectsUrl = "/dashboard/admin/gcp-projects",
  AdminUrl = "/dashboard/admin",
}

export const enum VirtualAgentURLs {
  DashboardURL = "/dashboard/virtual-agent/dashboard",
  ReachoutsURL = "/dashboard/virtual-agent/deploy/reachouts",
  GatekeeperURL = "/dashboard/virtual-agent/deploy/gateqeeper",
  QnowledgeRepoURL = "/dashboard/virtual-agent/design/dialogues/qnowledge-repository",
  NewQnowledgeBaseURL = "/dashboard/virtual-agent/deploy/new-qnowledge-base",
  QnowledgeRepoFAQsURL = "/dashboard/virtual-agent/deploy/qnowledge-repository-faq",
  PatientListURL = "/dashboard/virtual-agent/deploy/patient-list",
  DesignIntegrationHiveURL = "/dashboard/virtual-agent/design/qompositor",
  IntentLibraryURL = "/dashboard/virtual-agent/design/nlu/intent-library",
  EntityLibraryURL = "/dashboard/virtual-agent/design/nlu/entity-library",
  FlowLibraryURL = "/dashboard/virtual-agent/design/dialogues/flow-library",
  NLUSettingsURL = "/dashboard/virtual-agent/design/nlu/nlu-settings",
  IntegrationHiveURL = "/dashboard/virtual-agent/design/qompositor",
  NewWebhookURL = "/dashboard/virtual-agent/design/qompositor/new-webhook",
  NewActionURL = "/dashboard/virtual-agent/design/qompositor/new-action",
  EditActionURL = "/dashboard/virtual-agent/design/qompositor/edit-action",
  CDURL = "/dashboard/virtual-agent/design/conversation-designer",
  NLUOnlyIntents = "/dashboard",
  DefaultURL = "/dashboard/virtual-agent",
  VAurl = "/dashboard/virtual-agent",
  SkillUrl = "/dashboard/skill",
  IntentUrl = "/dashboard/intent",
}

export const enum CDURLs {
  OverviewURL = "/dashboard/conversation-designer/dialogues/overview",
  CreateNewFlowURL = "/dashboard/conversation-designer/dialogues/create-new-flow",
  DefaultURL = "/dashboard/conversation-designer",
}

export const enum InsightsURLs {
  LogsURL = "/dashboard/insights/logs",
  AnalyticsURL = "/dashboard/insights/analytics",
  DefaultURL = "/dashboard/insights",
}

export const enum OptimizerURLs {
  LandingPageURL = "/dashboard/sqrutinizer",
  VAConfigURL = "/dashboard/sqrutinizer/virtual-agent-configuration",
  DatasetsURL = "/dashboard/sqrutinizer/datasets",
  intentURL = "/dashboard/sqrutinizer/datasets/intent",
  OptimizerRunsURL = "/dashboard/sqrutinizer/optimization-runs",
  NewOptimizerRunsURL = "/dashboard/sqrutinizer/new-optimization-run",
  DefaultURL = "/dashboard/sqrutinizer",
}

export const enum AgentDesktopURLs {
  OverviewURL = "/dashboard/agent-desktop/overview",
  SettingsURL = "/dashboard/agent-desktop/settings",
  AgentManagementURL = "/dashboard/agent-desktop/agent-management",
  DefaultURL = "/dashboard/agent-desktop",
}

export const enum BillingURLs {
  ConsumptionURL = "/dashboard/billing/consumption",
  DefaultURL = "/dashboard/billing",
}
