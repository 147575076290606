import { MenuItem } from "../dashboard-drawer/dashboard-drawer";
import { RemoveRedEye, Topic } from "@mui/icons-material";
import {
  Dashboard,
  AddBusiness,
  GroupAdd,
  AutoGraph,
} from "@mui/icons-material";
import { SuperAdmin } from "../urls-config";

export const adminManagementMenus: MenuItem[] = [
  {
    name: "Dashboard",
    href: SuperAdmin.DashboardURL,
    icon: () => <Dashboard />,
  },
  {
    name: "Manage Tenant",
    href: SuperAdmin.TenantUrl,
    icon: () => <AddBusiness />,
  },
  {
    name: "Manage Users",
    href: SuperAdmin.ManageUsersUrl,
    icon: () => <GroupAdd />,
  },
  {
    name: "Usage Analytics",
    href: SuperAdmin.UsuageAnalyticsUrl,
    icon: () => <AutoGraph />,
  },
  {
    name: "GCP Projects",
    href: SuperAdmin.GcpProjectsUrl,
    icon: () => <Topic />, 
  },
];

// if (!["Super Admin", "Partner Admin Premium"].includes(role)) {
//   adminManagementMenus.splice(1, 1);
// }
