import { createAsyncThunk } from "../../../middlewares/asyncThunk";
import { fetchAnalyticsUrl, fetchLogs } from "../../../services/api/logging-service";
import ExtractAgentDetails from "../../../services/utils/extractAgentDetails";
import { VirtualAgentState } from "../virtual-agent/virtual-agent-reducer";

export const fetchLogsEffectAction = "logs/fetchList";
export const fetchAnalyticsUrlEffectAction = "logs/fetchAnalyticsUrl";

export const fetchLogsEffect = (type: string, page: number, limit: number, selectedAgent?:string) =>
  createAsyncThunk(fetchLogsEffectAction, async function (state) {
    const virtualAgentState = state.virtualAgent as VirtualAgentState;
    const AgentDetails = ExtractAgentDetails(virtualAgentState);
    const logs = await fetchLogs(type, AgentDetails, page, limit);
    const logData = { list: logs.rows, count: logs.totalRows }
    return logData;
});

export const fetchAnalyticsUrlEffect = (dashName:string, selectedAgent?:string) =>
  createAsyncThunk(fetchAnalyticsUrlEffectAction, async function (state) {
    const virtualAgentState = state.virtualAgent as VirtualAgentState;
    const AgentDetails = ExtractAgentDetails(virtualAgentState);
    const url = await fetchAnalyticsUrl(AgentDetails, dashName);
    return url;
});