import { Route, RouteProps } from "react-router";
import { CSSTransition } from "react-transition-group";

interface AnimatedRouteInterface extends RouteProps {
  animationClassName: string;
  timeout: number;
}

export default function AnimatedRoute({
  timeout,
  animationClassName,
  children,
  ...rest
}: AnimatedRouteInterface) {
  return (
    <Route {...rest}>
      {({ match }) => (
        <CSSTransition
          in={match != null}
          timeout={timeout}
          classNames={animationClassName}
          unmountOnExit
        >
          <>{children}</>
        </CSSTransition>
      )}
    </Route>
  );
}
