import { getAllUsersUrl } from "../../environment/environment.dev";
import { interceptor } from "../../middlewares/authenticatedFetch";
import responseParser from "../../middlewares/responseParser";
import { User, UserProfile } from "../../state/reducers/users/users-reducer";

const fetchUsers = async () => {
  const getAllUsers = await interceptor(`${getAllUsersUrl}`, {
    method: "GET",
  });

  const results = await getAllUsers.json();
  return results && results?.userList;
};

const AcceptPolicy = async (policyData: {
  email: string;
  isPolicyAccepted:boolean;
}) => {
  const nodeResponse = await interceptor(`${getAllUsersUrl}`, {
    method: "PUT",
    body: JSON.stringify(policyData),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const formattedResponse = await nodeResponse.json();
  return formattedResponse;
};

const createUser = async (user: User) => {
  const tempUserDataFormat = getFormatedUserData(user);
  const nodeResponse = await interceptor(`${getAllUsersUrl}`, {
    method: "POST",
    body: JSON.stringify(tempUserDataFormat),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await responseParser(nodeResponse);
  return result;
};

const editUser = async (user: User) => {
  const tempUserDataFormat = getFormatedUserData(user);
  const nodeResponse = await interceptor(`${getAllUsersUrl}`, {
    method: "PUT",
    body: JSON.stringify(tempUserDataFormat),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const formattedResponse = await nodeResponse.json();
  return formattedResponse;
};

const deleteUser = async (email: string) => {
  const response = await interceptor(`${getAllUsersUrl}?emailId=${email}`, {
    method: "DELETE",
  });
  const result = await responseParser(response);
  return result;
};

const profileUser = async (user: UserProfile) => {
  const tempUserDataFormat = {
    email: user.email,
    first_name: user.firstname,
    last_name: user.lastname,
    role: user.role,
  };
  const nodeResponse = await interceptor(`${getAllUsersUrl}`, {
    method: "PUT",
    body: JSON.stringify(tempUserDataFormat),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const formattedResponse = await nodeResponse.json();
  return formattedResponse;
};

function getFormatedUserData(user: User) {
  return {
    designation: user.designation,
    email: user.email,
    first_name: user.firstname,
    last_name: user.lastname,
    role: user.role,
    plan: ["q+"],
    organization: "quantiphi",
    created_by: user.email,
    virtual_agent_access: [...user.virtual_agent_access],
  };
}

export {
  fetchUsers,
  createUser,
  editUser,
  deleteUser,
  profileUser,
  AcceptPolicy,
};
