import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import { LightTheme } from "./themes/light-theme";
import { DarkTheme } from "./themes/dark-theme";
import "./animation.css";
import "./app.css";
import { useStoreContext } from "./state/agent-studio-context";

import { AppState } from "./state/reducers/app/app-reducer";
import { lazy, useEffect, useState } from "react";

import QLazyRoute from "./components/q-lazy-route/q-lazy-route";
import LoginContainer from "./screens/login-container/login-container";
// import conversationProfilesSlice from "./state/reducers/conversation-profiles/conversation-profiles-reducer";
import { SnackbarKey, SnackbarProvider, useSnackbar } from "notistack";
import ResetPassword from "./screens/reset-passowrd/reset-passowrd";
import ForgotPassword from "./screens/reset-passowrd/forgot-password";
import { Container as ModalContainer } from "react-modal-promise";
import {
  Button,
  IconButton,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import {
  initialState,
  WarehouseDataState,
} from "./state/reducers/library/intent-warehouse-reducer";
import { fetchWarehouseDomainWarehouseBotsEffect } from "./state/reducers/library/intent-warehouse-effects";
import useAgentState from "./hooks/useAgentState";

// import PatientContainer from "./screens/dashboard/patients/patients";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "qbot-component": any;
    }
  }
}

const Dashboard = lazy(
  () =>
    import(
      /* webpackPrefetch: true,webpackChunkName: "dashboard_chunk" */ "./screens/dashboard/dashboard"
    )
);
const Welcome = lazy(
  () =>
    import(
      /* webpackPrefetch: true,webpackChunkName: "welcome_one_chunk" */ "./screens/dashboard/welcome-two/welcome-two"
    )
);
const Privacy = lazy(
  () =>
    import(
      /* webpackPrefetch: true,webpackChunkName: "privacy_chunk" */ "./screens/dashboard/welcome-two/welcome-two"
    )
);

const IwWelcomeMessage = (props: { id: SnackbarKey }) => {
  const { id } = props;
  const { closeSnackbar } = useSnackbar();

  const { state, dispatch } = useStoreContext();

  const warehouseState = state.intentWarehouse as WarehouseDataState;

  function openBotDialog(domainId: string) {
    const botDomain = warehouseState.warehouse_domain_bots.list.find(
      (_) => _.domain_id === domainId
    );
    if (botDomain)
      dispatch({ type: "intent-warehouse/openBotDialog", payload: botDomain });
  }

  return (
    <>
      <Stack flexDirection="row">
        <Typography fontSize="0.93rem" sx={{ mr: 1 }}>
          Hi, Welcome to Qollective.CX Intent Warehouse. We have enabled a lite
          version of{" "}
          <Typography
            onClick={() =>
              openBotDialog("a15a3a8d-7c5c-43df-8332-6d9f584c9f86")
            }
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            display="inline"
            fontWeight={800}
            fontSize="0.93rem"
          >
            IBA
          </Typography>{" "}
          (Intelligent Banking Assistant) and{" "}
          <Typography
            onClick={() =>
              openBotDialog("7b0875de-39bd-45de-946a-f05fc29d3a9c")
            }
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            display="inline"
            fontWeight={800}
            fontSize="0.93rem"
          >
            PAM
          </Typography>{" "}
          (Post Surgical Assistance Manager) virtual agents in this instance.
          The same can be accessed by selecting the Banking or Healthcare domain
          respectively. Have a nice day!
        </Typography>
        {/* <IconButton color="inherit" onClick={() => closeSnackbar(id)}> */}
        <CloseRounded
          sx={{ cursor: "pointer" }}
          onClick={() => closeSnackbar(id)}
        />
        {/* </IconButton> */}
      </Stack>
    </>
  );
};

const NotificationStack = () => {
  const { state, dispatch } = useStoreContext();
  const app = state.app as AppState;
  const notifications = app.notification;
  // const [intentWarehouseState, setIntentState] =
  //   useState<WarehouseDataState>(initialState);

  // useEffect(() => {
  //   setIntentState(state.intentWarehouse as WarehouseDataState);
  // }, [state.intentWarehouse]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const closeNotification = (index: string) =>
    dispatch({ type: "app/notifications/closeMessage", payload: index });

  function openBotDialog(domainId: string) {
    const botDomain = (
      state.intentWarehouse as WarehouseDataState
    ).warehouse_domain_bots.list.find((_) => _.domain_id === domainId);
    if (botDomain)
      dispatch({ type: "intent-warehouse/openBotDialog", payload: botDomain });
  }

  const lastNotification = notifications[notifications.length - 1];

  useEffect(() => {
    if (
      lastNotification?.duration &&
      lastNotification?.message
      // intentWarehouseState
    ) {
      setTimeout(() => {
        enqueueSnackbar("", {
          key: lastNotification.id,
          variant: lastNotification.severity,
          persist: true,
          action: (id) => <IwWelcomeMessage id={id} />,
        });
      }, 2000);
    } else if (lastNotification?.message && !lastNotification?.duration) {
      enqueueSnackbar(lastNotification?.message, {
        key: lastNotification.id,
        variant: lastNotification.severity,
        autoHideDuration: lastNotification?.duration
          ? lastNotification?.duration
          : 5000,
        // action: (id) => (
        //   <IconButton color="inherit" onClick={() => closeNotification(id as string)}>
        //     <Close />
        //   </IconButton>
        // ),
      });
    }
  }, [notifications, notifications.length]);

  return null;
};

export default function App() {
  const { dispatch, state } = useStoreContext();

  const { overlay } = useAgentState();
  const { text, loading } = overlay;

  const app = state.app as AppState;

  //const theme = localStorage.getItem("darkMode") === "false" || localStorage.getItem("darkMode") === null ? LightTheme : DarkTheme;
  const theme = app.theme === "light" ? LightTheme : DarkTheme;
  // const environment = localStorage.getItem("ags_selected_environment");

  const mode = localStorage.getItem("darkMode");
  useEffect(() => {
    // setTimeout(() => {
    dispatch({
      type: "toggleTheme",
      payload:
        mode === "false" || localStorage.getItem("darkMode") === null
          ? "light"
          : "dark",
    });
    // }, 1000);
  }, [mode]);

  useEffect(() => {
    window.onresize = (event: Event) => {
      const target = event.target as Window;
      if (
        target &&
        !window.location.href.includes("dashboard/virtual-agent?selectedBot=")
      ) {
        dispatch({ type: "app/onWindowResize", payload: target.innerHeight });
      }
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <QLazyRoute
            timeout={1000}
            animationClassName="fade"
            path="/dashboard"
          >
            <Dashboard />
          </QLazyRoute>
          {/* <QLazyRoute
            timeout={1000}
            animationClassName="fade"
            path="/onboarding/welcome"
            exact
          >
            <Welcome />
          </QLazyRoute> */}
          {/* <QLazyRoute
            timeout={1000}
            animationClassName="fade"
            path="/onboarding/privacy-policy"
            exact
          >
            <Privacy />
          </QLazyRoute> */}
          <Route path="/login/*" exact children={<LoginContainer />} />
          {/* <Route path="/reset-password" exact children={<ResetPassword />} />
          <Route path="/forgot-password" exact children={<ForgotPassword />} /> */}
          <QLazyRoute
            timeout={1000}
            animationClassName="fade"
            path="*"
            exact
            children={<Redirect to="/dashboard" />}
          />
        </Switch>
        <SnackbarProvider
          maxSnack={6}
          variant="info"
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          style={{ maxWidth: 300 }}
          hideIconVariant
        >
          <NotificationStack />
        </SnackbarProvider>
        {/* <QNotificationStack
          notifications={notifications}
          handleClose={(index: number) =>
            dispatch({ type: "app/notifications/closeMessage", payload: index })
          }
        /> */}
        <ModalContainer />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            spacing={2}
          >
            <CircularProgress color="inherit" size="24px" />
            <Typography mt={0.5}>{text}</Typography>
          </Stack>
        </Backdrop>
      </BrowserRouter>
    </ThemeProvider>
  );
}
