import { createAsyncThunk } from "../../../middlewares/asyncThunk";
import {
  fetchUsers,
  deleteUser,
  createUser,
  editUser,
  profileUser
} from "../../../services/api/users-service";
import { User, UserProfile, UsersState } from "./users-reducer";

export const fetchUsersEffectAction = "users/fetchList";
export const createUsersEffectAction = "users/createUser";
export const editUserEffectAction = "users/editUser";
export const deleteUsersEffectAction = "users/deleteUserById";
export const profileUserEffectAction = "users/userProfile";

export const fetchUsersEffect = () =>
  createAsyncThunk(fetchUsersEffectAction, async function () {
    const users = await fetchUsers();
    return users;
  });

export const createUserEffect = (user: User) =>
  createAsyncThunk(createUsersEffectAction, async function () {
    await createUser(user);
    return user;
  });

export const editUserEffect = (user: User) =>
  createAsyncThunk(editUserEffectAction, async function () {
    await editUser(user);
    return user;
  });

export const deleteUserEffect = (id: string) =>
  createAsyncThunk(deleteUsersEffectAction, async function () {
    const response = await deleteUser(id);
    return { response, id };
  });

export const profileSaveEffect = (user: UserProfile) =>
  createAsyncThunk(profileUserEffectAction, async function () {
    await profileUser(user);
    return user;
  });