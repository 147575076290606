export const interceptor= async (url: RequestInfo, options?: RequestInit & { timeout?: number } ) => {
  const sessionToken: string = sessionStorage.getItem("ags_token") || "";
  const controller = new AbortController();
  const timeout = options?.timeout ?? 12000;
  const id = setTimeout(() => controller.abort(), timeout);
  const wrapperOptions: RequestInit | undefined = options
    ? {
        ...options,
        headers: {
          ...options?.headers,
          "Authorization": `Bearer ${sessionToken}`,
        },
        signal: controller.signal
      }
    : {
        headers: {
          "Authorization": `Bearer ${sessionToken}`,
        },
      };

  const response = await fetch(url, wrapperOptions);
  
  clearTimeout(id);

  return response;
};
