import { AlertColor } from "@mui/material";
import {
  CHANGE_PASSWORD_ACTION,
  FORGOT_PASSWORD_ACTION,
  RESET_PASSWORD_ACTION,
  USER_GOOGLE_LOGIN_ACTION,
  USER_LOGIN_ACTION,
  VERIFY_OTP_ACTION,
} from "../login/login-reducer";
import {
  createUsersEffectAction,
  deleteUsersEffectAction,
  editUserEffectAction,
  profileUserEffectAction,
} from "../users/users-effects";
import {
  createBotRetryEffectAction,
  createMigrationEffectAction,
  deleteAgentEffectAction,
  listAllVirtualAgentEffectAction,
  saveAndTrainEffectAction,
  updateAgentEffectAction,
} from "../virtual-agent/virtual-agent-effect";
import { fetchGcpProjectsEffectAction } from "./app.effects";
import {
  fetchAnalyticsUrlEffectAction,
  fetchLogsEffectAction,
} from "../logging/logging-effects";
import {
  fetchWarehouseDomainIntentsEffectAction,
  fetchWarehouseDomainsEffectAction,
  uploadBotPipelineEffectAction,
  uploadSkillPipelineEffectAction,
  deployCXVAEffectAction,
  deploySkillCXVAEffectAction,
  createIntentAction,
  addPartnerAdminEffectAction,
  addPartnerUserAdminEffectAction,
  deletePartnerUserAdminEffectAction,
  updatePartnerUserAdminEffectAction,
  deleteGcpProjectsEffectAction,
  verifyGcpProjectsEffectAction,
  createGcpProjectsEffectAction,
  resendMailForGcpProjectEffectAction,
  fetchAuditLogsEffectAction,
  fetchExportLogsEffectAction,
  fetchApplicationLogsEffectAction,
  fetchActivateDemoModeEffectAction,
} from "../library/intent-warehouse-effects";

import { v4 } from "uuid";

const sliceName = "app";

export interface Notification {
  severity: AlertColor;
  message: string;
  timestamp: number;
  id: string;
  duration?: number;
}

export interface AppState {
  version: string;
  theme: string;
  language: string;
  notification: Notification[];
  projects: {
    list: any[];
    selectedProject: object;
    openProjectModal: boolean;
    loading: boolean;
  };
  windowInnerHeight: number;
  micPermissionGranted: boolean;
}

const selectedProject = sessionStorage.getItem("ags_selected_project") || "{}";

const initialState: AppState = {
  version: "0.0.1",
  theme: "light",
  language: "en-US",
  notification: [],
  projects: {
    list: [],
    selectedProject: JSON.parse(selectedProject),
    openProjectModal: true,
    loading: false,
  },
  windowInnerHeight: window.innerHeight,
  micPermissionGranted: false,
};

function returnStateForGcpProjectScreen(
  state: AppState,
  msgType: AlertColor,
  message: string
) {
  return {
    ...state,
    notification: state.notification.concat([
      {
        severity: msgType,
        message,
        timestamp: Date.now(),
        id: v4(),
      },
    ]),
  };
}

function reducer(state: AppState, action: { type: string; payload?: any }) {
  switch (action.type) {
    case "toggleTheme":
      return {
        ...state,
        theme: action.payload === "light" ? "light" : "dark",
      };
    case "setLang":
      return {
        ...state,
        language: action.payload,
      };
    case `${USER_LOGIN_ACTION}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "User logged in successfully!",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${USER_LOGIN_ACTION}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message:
              "User login failed! Please Check the username and password!",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${RESET_PASSWORD_ACTION}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Password changed successfully!",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${RESET_PASSWORD_ACTION}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: action?.payload?.msg,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${CHANGE_PASSWORD_ACTION}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Change password successfully!",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${CHANGE_PASSWORD_ACTION}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: "Failed to change password. Please try again.",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${FORGOT_PASSWORD_ACTION}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Reset link sent to your email id.",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${FORGOT_PASSWORD_ACTION}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: action?.payload?.message,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${VERIFY_OTP_ACTION}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Password changed Successfully.",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${VERIFY_OTP_ACTION}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: action?.payload?.msg,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${deleteUsersEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: `User Deleted Succesfully !`,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${createUsersEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: `User Created Succesfully !`,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${createUsersEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: `User already exists!`,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${editUserEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: `User Updated Succesfully !`,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${profileUserEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: `Failed to update. Please try again.`,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${profileUserEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: `User Updated Succesfully !`,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${listAllVirtualAgentEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: "Error Occured While Fetching Agents! Please Try Again.",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case "app/notifications/closeMessage":
      return {
        ...state,
        notification: state.notification.filter(
          (_, index) => _.id !== action.payload
        ),
      };
    case `${fetchGcpProjectsEffectAction}-completed`:
      return {
        ...state,
        projects: {
          ...state.projects,
          list: action.payload.list,
          loading: false,
        },
      };
    case `${fetchGcpProjectsEffectAction}-pending`:
      return {
        ...state,
        projects: {
          ...state.projects,
          loading: true,
        },
      };
    case "app/dispatchSelectedProject": {
      sessionStorage.setItem(
        "ags_selected_project",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        projects: {
          ...state.projects,
          openProjectModal: false,
          selectedProject: action.payload,
        },
      };
    }
    case "login/login-user-completed":
      return {
        ...state,
        projects: {
          ...state.projects,
          openProjectModal: true,
        },
      };
    case `${USER_GOOGLE_LOGIN_ACTION}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "User logged in successfully!",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${USER_GOOGLE_LOGIN_ACTION}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: "User login failed! Please Check your email id!",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${deployCXVAEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Virtual Agent deployed",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${deployCXVAEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message:
              action.payload.message ?? "Virtual agent deployment failed",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${deploySkillCXVAEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Skill restored",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${deploySkillCXVAEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message:
              action.payload.message ?? "Virtual agent deployment failed",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case "app/openProjectsModal":
      return {
        ...state,
        projects: {
          ...state.projects,
          openProjectModal: true,
        },
      };
    case `${fetchGcpProjectsEffectAction}-rejected`:
      return {
        ...state,
        projects: {
          ...state.projects,
          // list: [
          //   {
          //     name: "qai-cxt-dev",
          //     projectId: "q-gcp-psc1-qai-cxt-dev-21-09",
          //     project_type: "gcp",
          //   },
          //   {
          //     name: "accenture-slave",
          //     projectId: "accenture-slave",
          //     project_type: "gcp",
          //   },
          //   {
          //     name: "Quickstart",
          //     projectId: "quickstart-1550599603611",
          //     project_type: "gcp",
          //   },
          // ],
          loading: false,
          list: [],
        },
      };
    case `${fetchLogsEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: action.payload.message,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${fetchAnalyticsUrlEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message:
              action.payload?.message || "Unable to load analytics dashboard",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case "app/showMessage":
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: action.payload,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${fetchWarehouseDomainIntentsEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: "Unable to fetch intents at this time",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${fetchWarehouseDomainsEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: "Unable to fetch domains at this time",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case "app/onNotification": {
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: action.payload.severity,
            message: action.payload.message,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    }
    case `${saveAndTrainEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Save & Train Job Created Successfully!",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${createMigrationEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Migration Job Created Successfully!",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${createMigrationEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: "Unable to create migration job!",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${createBotRetryEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Bot Creation retry Job Created Successfully!",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${createBotRetryEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: "Unable to create bot creation retry job!",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${deleteAgentEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Agent deleted",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${deleteAgentEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: "Agent deletion failed",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${updateAgentEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: action.payload?.msg,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${updateAgentEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: "Agent update failed",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${uploadBotPipelineEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Virtual Agent Deployed Successfully",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${uploadBotPipelineEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: "Virtual Agent failed to deploy",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${createIntentAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Intent Added  Successfully",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${createIntentAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: "Failed to add intent",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${uploadSkillPipelineEffectAction}-completed`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "Virtual Agent Deployed Successfully",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case `${uploadSkillPipelineEffectAction}-rejected`:
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "error",
            message: "Virtual Agent failed to deploy",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };

    case `${addPartnerUserAdminEffectAction}-completed`:
      return returnStateForGcpProjectScreen(
        state,
        "success",
        action.payload.message
      );
    case `${addPartnerUserAdminEffectAction}-rejected`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.message
      );

    case `${deletePartnerUserAdminEffectAction}-completed`:
      return returnStateForGcpProjectScreen(
        state,
        "success",
        action.payload.message
      );
    case `${deletePartnerUserAdminEffectAction}-rejected`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.message
      );

    case `${updatePartnerUserAdminEffectAction}-completed`:
      return returnStateForGcpProjectScreen(
        state,
        "success",
        action.payload.message
      );
    case `${updatePartnerUserAdminEffectAction}-rejected`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.message
      );

    case `${deleteGcpProjectsEffectAction}-completed`:
      return returnStateForGcpProjectScreen(
        state,
        "success",
        action.payload.message
      );
    case `${deleteGcpProjectsEffectAction}-rejected`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.message
      );

    case `${verifyGcpProjectsEffectAction}-completed`:
      return returnStateForGcpProjectScreen(
        state,
        "success",
        action.payload.message
      );

    case `${verifyGcpProjectsEffectAction}-rejected`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.message
      );

    case `${createGcpProjectsEffectAction}-completed`:
      return returnStateForGcpProjectScreen(
        state,
        "success",
        action.payload.message
      );
    case `${createGcpProjectsEffectAction}-rejected`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.message
      );

    case `${resendMailForGcpProjectEffectAction}-completed`:
      return returnStateForGcpProjectScreen(
        state,
        "success",
        action.payload.message
      );
    case `${resendMailForGcpProjectEffectAction}-rejected`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.message
      );

    case `${addPartnerAdminEffectAction}-completed`:
      return returnStateForGcpProjectScreen(
        state,
        "success",
        action.payload.message
      );
    case `${addPartnerAdminEffectAction}-rejected`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.message
      );

    case `${fetchActivateDemoModeEffectAction}-rejected`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.message
      );

    // --- Usage Analytics --- //
    case `${fetchAuditLogsEffectAction}-rejected`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.message
      );
    case `${fetchExportLogsEffectAction}-rejected`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.message
      );
    case `${fetchApplicationLogsEffectAction}-completed`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.error
      );
    case `${fetchApplicationLogsEffectAction}-rejected`:
      return returnStateForGcpProjectScreen(
        state,
        "error",
        action.payload.message
      );
    // --- Usage Analytics End --- //

    case "app/mic-permission-granted":
      return {
        ...state,
        micPermissionGranted: action.payload,
      };

    case "app/showMessage-success":
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: "success",
            message: action.payload,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case "app/onWindowResize":
      return {
        ...state,
        windowInnerHeight: action.payload,
      };
    case "app/notification-msg":
      return {
        ...state,
        notification: state.notification.concat([
          {
            severity: action.payload.severity,
            message: action.payload.msg,
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    case "app/logOut":
      return {
        ...state,
        ...initialState,
        notification: state.notification.concat([
          {
            severity: "success",
            message: "User logged out successfully",
            timestamp: Date.now(),
            id: v4(),
          },
        ]),
      };
    default:
      return {
        ...state,
      };
  }
}

const appSlice = {
  sliceName,
  initialState,
  reducer,
};

export default appSlice;
