import {
  fetchUsersEffectAction,
  createUsersEffectAction,
  deleteUsersEffectAction,
  editUserEffectAction,
  profileUserEffectAction,
} from "./users-effects";

const sliceName = "users";

export interface User {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  virtual_agent_access: virtualAgent[];
  role: string;
  designation?: string;
}

export interface virtualAgent {
  name: string;
  bot_id: string;
}
export interface virtualAgentBots {
  name: string;
  id: string;
}

export interface UsersState {
  list: User[];
  profile?: UserProfile;
  loading: boolean;
  inlineLoading: boolean;
  selectedUser?: User;
  createNewUserModal: boolean;
  openEditModal: boolean;
  openDeleteUserDialog: boolean;
  query?: string;
}

export interface UserProfile {
  firstname: string;
  lastname: string;
  email: string;
  role: string;
}

const initialState: UsersState = {
  list: [],
  loading: false,
  inlineLoading: false,
  createNewUserModal: false,
  openDeleteUserDialog: false,
  openEditModal: false,
};

function reducer(state = initialState, action: { type?: string; payload?: any }) {
  switch (action.type) {
    case `${fetchUsersEffectAction}-pending`:
      return {
        ...state,
        loading: true,
      };
    case `${fetchUsersEffectAction}-completed`:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case `${deleteUsersEffectAction}-pending`:
      return {
        ...state,
        inlineLoading: true,
      };
    case `${deleteUsersEffectAction}-completed`:
      const deleteUserList = state?.list
        .filter((_) => _.email !== action.payload.id)
      return {
        ...state,
        list: deleteUserList,
        inlineLoading: false,
        openDeleteUserDialog: false
      };
    case `${createUsersEffectAction}-pending`:
      return {
        ...state,
      };
    case `${createUsersEffectAction}-rejected`:
      return {
        ...state,
        createNewUserModal: false,
      };
    case `${createUsersEffectAction}-completed`:
      return {
        ...state,
        list: state?.list.concat([action.payload]),
        createNewUserModal: false,
      };
    case `${editUserEffectAction}-pending`:
      return {
        ...state,
      };
    case `${editUserEffectAction}-completed`:
      const newUserList = state?.list
        .filter((_) => _.email !== action.payload.email)
        .concat([action.payload])
        .sort( (a,b) => a.id - b.id )
      return {
        ...state,
        list: newUserList,
        openEditModal: false,
      };
    case `${profileUserEffectAction}-pending`:
      return {
        ...state,
      };
    case `${profileUserEffectAction}-completed`:
      return {
        ...state,
        profile: {
          data: action.payload,
          loading: false
        }
      };
    case "users/openCreateUserDialog":
      return {
        ...state,
        createNewUserModal: true,
      };
    case "users/openEditUserDialog":
      return {
        ...state,
        openEditModal: true,
        selectedUser: action.payload,
      };
    case "users/closeCreateUserDialog":
      return {
        ...state,
        createNewUserModal: false,
        openEditModal: false,
      };
    case "users/searchUser":
      return {
        ...state,
        query: action.payload
      };
    case "users/openDeleteUserConfirmationDialog":
      return {
        ...state,
        openDeleteUserDialog: true,
        selectedUser: action.payload,
      }
    case "users/closeDeleteUserConfirmationDialog":
      return {
        ...state,
        openDeleteUserDialog: false
      }
    default:
      return {
        ...state,
      };
  }
}

const usersSlice = {
  sliceName,
  initialState,
  reducer,
};

export default usersSlice;
